import { toast } from 'react-toastify';

// id's of chains that are default to wallets (mainnet + testnets)
const DEFAULT_CHAINS = [1, 3, 4, 5, 42];

// for custom networks; try to add chain, if add is successful, switch will occur
// for default networks; just try switching
export default async function changeNetwork(newNetwork) {
  const isDefault = DEFAULT_CHAINS.includes(newNetwork.chainId);

  var data;
  if (isDefault) {
    data = [
      {
        chainId: '0x' + newNetwork.chainId.toString(16),
      },
    ];
  } else {
    data = [
      {
        chainId: '0x' + newNetwork.chainId.toString(16),
        chainName: newNetwork.fullName,
        nativeCurrency: newNetwork.nativeCurrency,
        rpcUrls: [newNetwork.rpcUrl],
        blockExplorerUrls: [newNetwork.blockExplorer],
      },
    ];
  }

  try {
    const tx = await window.ethereum.request({
      method: isDefault
        ? 'wallet_switchEthereumChain'
        : 'wallet_addEthereumChain',
      params: data,
    });

    if (tx) {
      console.log(tx);
    }

    window.localStorage.setItem('network', newNetwork.chainId);
    window.location.reload();
  } catch (err) {
    console.log(err);
    toast.error(
      `Could not prompt wallet to change network, please change to ${newNetwork.name} manually`
    );
  }
}
