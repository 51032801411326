// icon imports
import ethIcon from './assets/chains/ethereum.png';
import bscIcon from './assets/chains/bsc.png';
import xdaiIcon from './assets/chains/xdai.png';
import polygonIcon from './assets/chains/polygon.png';
import avalancheIcon from './assets/chains/avalanche.png';
import fantomIcon from './assets/chains/fantom.png';
import harmonyIcon from './assets/chains/harmony.png';
import auroraIcon from './assets/chains/aurora.png';

// https://infura.io/dashboard/ethereum
export const INFURA_ID = process.env.REACT_APP_INFURA_ID || 'b8d4603a11174c09893de8d9c6af028d';
// https://etherscan.io/myapikey
export const ETHERSCAN_KEY = 'G33PBPTD39DP7913CWN5X4YZVRWXQJ5QUC';

// list of known/popular networks
const NETWORKS = {
  mainnet: {
    name: 'Mainnet',
    fullName: 'Ethereum Mainnet',
    color: '#ff8b9e',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: 1,
    blockExplorer: 'https://etherscan.io',
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    icon: ethIcon,
  },
  binance: {
    name: 'BSC',
    fullName: 'Binance Smart Chain Mainnet',
    color: '#fe9d24',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    chainId: 56,
    blockExplorer: 'https://bscscan.com',
    rpcUrl: `https://bsc-dataseed1.binance.org/`,
    icon: bscIcon,
  },
  xdai: {
    name: 'xDAI',
    fullName: 'xDAI Chain',
    color: '#33a19c',
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18,
    },
    chainId: 100,
    blockExplorer: 'https://blockscout.com/poa/xdai',
    rpcUrl: 'https://rpc.xdaichain.com',
    icon: xdaiIcon,
  },
  polygon: {
    name: 'Polygon',
    fullName: 'Polygon Mainnet',
    color: '#2bbdf7',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    chainId: 137,
    blockExplorer: 'https://polygonscan.com',
    rpcUrl: 'https://polygon-rpc.com/',
    icon: polygonIcon,
  },
  avalanche: {
    name: 'Avalanche',
    fullName: 'Avalanche Mainnet',
    color: '#ec303b',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    chainId: 43114,
    blockExplorer: 'https://snowtrace.io',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    icon: avalancheIcon,
  },
  fantom: {
    name: 'Fantom',
    fullName: 'Fantom Opera',
    color: '#00ade9',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
    chainId: 250,
    blockExplorer: 'https://ftmscan.com',
    rpcUrl: 'https://rpc.ftm.tools',
    icon: fantomIcon,
  },
  harmony: {
    name: 'Harmony',
    fullName: 'Harmony Mainnet Shard 0',
    color: '#00d1cc',
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
    chainId: 1666600000,
    blockExplorer: 'https://explorer.harmony.one',
    rpcUrl: 'https://api.harmony.one',
    icon: harmonyIcon,
  },
  aurora: {
    name: 'Aurora',
    fullName: 'Aurora MainNet',
    color: '#5adae1',
    nativeCurrency: {
      name: 'aETH',
      symbol: 'aETH',
      decimals: 18,
    },
    chainId: 1313161554,
    blockExplorer: 'https://explorer.mainnet.aurora.dev',
    rpcUrl: 'https://mainnet.aurora.dev',
    icon: auroraIcon,
  },
  localhost: {
    name: 'localhost',
    fullName: 'Local Chain Testnet',
    color: '#666666',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: 1337,
    blockExplorer: '',
    rpcUrl: `http://${window.location.hostname}:8545`,
  },
  goerli: {
    name: 'goerli',
    fullName: 'Goerli Testnet',
    color: '#0975F6',
    chainId: 5,
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
    blockExplorer: 'https://goerli.etherscan.io',
    faucet: 'https://goerli-faucet.slock.it/',
  },
  /*
    kovan: {
        name: 'kovan',
        fullName: 'Kovan Testnet',
        color: '#7003DD',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        chainId: 42,
        rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
        blockExplorer: 'https://kovan.etherscan.io',
        faucet: 'https://gitter.im/kovan-testnet/faucet', // https://faucet.kovan.network/
    },
    rinkeby: {
        name: "Rinkeby",
        color: "#e0d068",
        chainId: 4,
        rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
        faucet: "https://faucet.rinkeby.io/",
        blockExplorer: "https://rinkeby.etherscan.io/",
    },
    ropsten: {
        name: "Ropsten",
        color: "#F60D09",
        chainId: 3,
        faucet: "https://faucet.ropsten.be/",
        blockExplorer: "https://ropsten.etherscan.io/",
        rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
    },
    mumbai: {
        name: "Mumbai",
        color: "#92D9FA",
        chainId: 80001,
        price: 1,
        gasPrice: 1000000000,
        rpcUrl: "https://rpc-mumbai.maticvigil.com",
        faucet: "https://faucet.matic.network/",
        blockExplorer: "https://mumbai-explorer.matic.today/",
    },
    localArbitrum: {
        name: "localArbitrum",
        color: "#50a0ea",
        chainId: 153869338190755,
        blockExplorer: "",
        rpcUrl: `http://localhost:8547`,
    },
    localArbitrumL1: {
        name: "localArbitrumL1",
        color: "#50a0ea",
        chainId: 44010,
        blockExplorer: "",
        rpcUrl: `http://localhost:7545`,
    },
    rinkebyArbitrum: {
        name: "Arbitrum Testnet",
        color: "#50a0ea",
        chainId: 421611,
        blockExplorer: "https://rinkeby-explorer.arbitrum.io/#/",
        rpcUrl: `https://rinkeby.arbitrum.io/rpc`,
    },
    arbitrum: {
        name: "Arbitrum",
        color: "#50a0ea",
        chainId: 42161,
        blockExplorer: "https://explorer.arbitrum.io/#/",
        rpcUrl: `https://arb1.arbitrum.io/rpc`,
        gasPrice: 0,
    },
    localOptimismL1: {
        name: "localOptimismL1",
        color: "#f01a37",
        chainId: 31337,
        blockExplorer: "",
        rpcUrl: "http://" + window.location.hostname + ":9545",
    },
    localOptimism: {
        name: "localOptimism",
        color: "#f01a37",
        chainId: 420,
        blockExplorer: "",
        rpcUrl: "http://" + window.location.hostname + ":8545",
        gasPrice: 0,
    },
    kovanOptimism: {
        name: "kovanOptimism",
        color: "#f01a37",
        chainId: 69,
        blockExplorer: "https://kovan-optimistic.etherscan.io/",
        rpcUrl: `https://kovan.optimism.io`,
        gasPrice: 0,
    },
    optimism: {
        name: "optimism",
        color: "#f01a37",
        chainId: 10,
        blockExplorer: "https://optimistic.etherscan.io/",
        rpcUrl: `https://mainnet.optimism.io`,
    },
    */
};
export default NETWORKS;
