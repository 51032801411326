import { createContext, useState, useEffect, ReactChild } from 'react';

import { BigNumber, Contract, ethers } from 'ethers';
import { toast } from 'react-toastify';

import {
  ID_ECO,
  ID_TIMED_POLICIES,
  POLICY,
  ID_CURRENCY_GOVERNANCE,
  ethProvider,
  ID_TRUSTED_NODES,
  ID_CURRENCY_TIMER,
} from '../../constants';

import currencyTimerAbi from '../../assets/abi/CurrencyTimer.json';
import { Address, ContractAddressesInterface } from '../../types';

const DEFAULT_CONTRACT_ADDRESSES: ContractAddressesInterface = {
  eco: null,
  timedPolicies: null,
  currencyGovernance: null,
  trustedNodes: null,
  currencyTimer: null,
};

export const InfuraContractAddressContext =
  createContext<ContractAddressesInterface>(DEFAULT_CONTRACT_ADDRESSES);

/**
 * ContractsProvider
 *
 * This functional component provider interacts with the registry through the Policy.sol contract
 * if policyAddress is not right, errors will occur when we try to call policyFor here.
 *
 * This function acquires the addresses of all necessary contracts in the system
 * and provides them for any sub components throughout the app via useContractAddresses()
 *
 */

type ContractAddressProviderProps = {
  children: ReactChild;
};

export default function ContractsProvider({
  children,
}: ContractAddressProviderProps) {
  const [contracts, setContracts] = useState<ContractAddressesInterface>(
    DEFAULT_CONTRACT_ADDRESSES
  );

  async function refresh() {
    let eco, timedPolicies, currencyGovernance, trustedNodes, currencyTimer;

    // check for new contract addresses
    try {
      eco = await POLICY.policyFor(ID_ECO);
      timedPolicies = await POLICY.policyFor(ID_TIMED_POLICIES);
      currencyGovernance = await POLICY.policyFor(ID_CURRENCY_GOVERNANCE);
      trustedNodes = await POLICY.policyFor(ID_TRUSTED_NODES);
      currencyTimer = await POLICY.policyFor(ID_CURRENCY_TIMER);

      const contracts: ContractAddressesInterface = {
        eco: new Address(eco),
        timedPolicies: new Address(timedPolicies),
        currencyGovernance: new Address(currencyGovernance),
        trustedNodes: new Address(trustedNodes),
        currencyTimer: new Address(currencyTimer),
      };
      console.log('Contracts:', contracts);

      setContracts(contracts);
    } catch (err) {
      // failed to get addresses
      console.log(err);
      if (process.env.NODE_ENV !== 'production') {
        alert('Failed to fetch contracts (check policyAddress in src/env.js)');
      } else {
        // retry in 30 seconds
        setTimeout(() => {
          refresh();
        }, 30_000);
      }
    }
  }

  // on app load, get contracts, then listen for new generation event
  useEffect(() => {
    refresh();
  }, []);

  // listen for generation increment when timed policies contract is updated
  useEffect(() => {
    if (contracts.currencyTimer !== null) {
      const currencyTimerContract = new ethers.Contract(
        contracts.currencyTimer.toString(),
        currencyTimerAbi.abi,
        ethProvider()
      );

      currencyTimerContract.on(
        'NewCurrencyGovernance',
        (address: string, generation: BigNumber) => {
          toast.info('A New Eco Generation is Here!');
          refresh();
        }
      );

      return () => {
        currencyTimerContract.removeAllListeners();
      };
    }
  }, [contracts.currencyTimer?.toString()]);

  return (
    <InfuraContractAddressContext.Provider value={contracts}>
      {/* If a call to refresh errors and contracts is null, the app will not render */}
      {contracts ? children : null}
    </InfuraContractAddressContext.Provider>
  );
}
