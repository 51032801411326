import { gql } from '@apollo/client';

type SubgraphTrustee = {
  id: string;
};

type SubgraphTrusteeCohort = {
  number: string;
  blockNumber: string;
  trustees: SubgraphTrustee[];
};

export type PastTrusteesQueryResult = {
  after: SubgraphTrusteeCohort[];
  before: SubgraphTrusteeCohort[];
};

/**
 * Get all trustee cohorts after blockNumber, order descending (newest first)
 * will get all of the trustee cohorts since a block Number (ones that were created after the block number, and the one that was most recent when the block was mined)
 */

export const PAST_TRUSTEES = (blockNumber: string) => gql`
    query PastTrustees {
        after: trusteeCohorts(
            where: { blockNumber_gt: ${blockNumber} }
            orderBy: number
            orderDirection: desc
            limit: 1
        ) {
            number
            blockNumber
            trustees {
                id
            }
        }
        before: trusteeCohorts(
            where: { blockNumber_lte: ${blockNumber} }
            first: 1
            orderBy: number
            orderDirection: desc
            limit: 1
        ) {
            number
            blockNumber
            trustees {
                id
            }
        }
    }
`;
