import { useEffect, useState } from 'react';

import { Button, Typography, Divider, Row, Col, Modal } from 'antd';

import { useContractAddresses } from '../providers/ContractAddressProvider';
import { useGovernance } from '../providers/GovernanceProvider';

import { toast } from 'react-toastify';
import { BigNumber, ethers, utils } from 'ethers';
import moment from 'moment';

import { download, toastTxError } from '../helpers';
import CommitList from '../components/CommitList';
import ProposalTable from '../components/ProposalTable';

import currencyGovernanceABI from '../assets/abi/CurrencyGovernance.json';
import { GovernanceStage } from '../types';
import InfoBubble from '../components/InfoBubble';

/**
 * Commit
 *
 * sort the list of proposals into the order of your choice,
 * then commit your vote
 *
 * upon successful commit, download a file for the trustee to use in the reveal stage (contains seed, list of trustee proposals that they ranked)
 *
 * also display list of trustees that have committed
 *
 */

export default function Commit({ signer }) {
  const contracts = useContractAddresses();
  const gov = useGovernance();

  const [sortedList, setSortedList] = useState([]);

  const [seed, setSeed] = useState(null);
  const [vote, setVote] = useState(null);
  const [hash, setHash] = useState(null);
  const [address, setAddress] = useState(null);

  // submit confirmation modal open or not
  const [submitOpen, setSubmitOpen] = useState(false);
  // processing commit transaction
  const [processing, setProcessing] = useState(false);

  // successful commit modal open or not
  const [successOpen, setSuccessOpen] = useState(false);

  const [downloaded, setDownloaded] = useState(false);

  function downloadCommit() {
    // save commit info to file and download the file
    download(
      `commit-${moment(Date.now()).format('DD-MM-YYYY')}-${address}.txt`,
      JSON.stringify(
        {
          seed: seed,
          vote: vote.reduce((acc, cur, i) => ({ ...acc, [i + 1]: cur }), {}),
          hash: hash,
        },
        null,
        4
      )
    );

    setDownloaded(true);
  }

  function onModalClose() {
    if (!downloaded) {
      return toast.error(
        'You have not downloaded your commit file, you will need it in order to reveal'
      );
    }

    setSuccessOpen(false);

    setHash(null);
    setSeed(null);
    setVote(null);
    setAddress(null);
    setDownloaded(false);
  }

  async function commitVote(e) {
    if (e) {
      e.preventDefault();
    }

    if (!signer) {
      toast.error('Please connect a wallet to commit your vote');
      return;
    }

    if (sortedList.length < 1) {
      toast.error('There are no proposals to commit');
      return;
    }

    setProcessing(true);

    let commitTx;
    try {
      const myAddress = await signer.getAddress();

      const currencyGovernance = new ethers.Contract(
        contracts.currencyGovernance.toString(),
        currencyGovernanceABI.abi,
        signer
      );

      // generate seed
      const sd = BigNumber.from(utils.randomBytes(32)).toHexString();

      // map selection to trustee addresses
      const vt = sortedList.map((p) => p.trustee.toString());

      // generate hash
      const formattedVote = vt
        .map((address, index, array) => ({
          proposal: address.toLowerCase(),
          score: array.length - index,
        }))
        .sort((a, b) => a.proposal.localeCompare(b.proposal));

      const hsh = ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(
          ['bytes32', 'address', '(address proposal, uint256 score)[]'],
          [sd, myAddress, formattedVote]
        )
      );

      // send transaction to chain
      commitTx = await currencyGovernance.commit(hsh);

      const committed = await commitTx.wait();

      if (!committed.status) {
        console.log(committed);
        throw new Error('Error while committing vote');
      }

      toast.success('Successful Commit');

      // open modal with seed
      setHash(hsh);
      setSeed(sd);
      setVote(vt);
      setAddress(myAddress);

      // close submit modal and open success modal
      setSubmitOpen(false);
      setSuccessOpen(true);
    } catch (err) {
      console.log(err);
      toastTxError({
        txHash: commitTx?.hash,
        message: 'Failed to commit vote',
        err,
      });
    }
    setProcessing(false);
  }

  // update local copy when global stage changes (shouldn't occur unless in Propose stage)
  useEffect(() => {
    if (gov.proposals.length) {
      // check and sort against cache (if exists)
      let cachedSortedListString = window.sessionStorage.getItem('commit');
      if (cachedSortedListString) {
        // get cached list of trustee addresses (string[])
        const cachedSortedList = [...JSON.parse(cachedSortedListString)];

        // sort proposals that aren't newer than cache
        const cachedProposals = gov.proposals
          .filter((proposal) =>
            cachedSortedList.includes(proposal.trustee.toString())
          )
          .sort(
            (a, b) =>
              cachedSortedList.indexOf(a.trustee.toString()) -
              cachedSortedList.indexOf(b.trustee.toString())
          );

        // check for new proposals and append to previously sorted array
        const newProposals = gov.proposals.filter(
          (proposal) =>
            !cachedProposals.some((sortedProposal) =>
              sortedProposal.trustee.eq(proposal.trustee)
            )
        );
        setSortedList(
          [...cachedProposals, ...newProposals].map((proposal) => ({
            ...proposal,
            id: proposal.trustee.toString(),
          }))
        );
      } else {
        setSortedList(
          gov.proposals.map((proposal) => ({
            ...proposal,
            id: proposal.trustee.toString(),
          }))
        );
      }
    }
  }, [gov.proposals]);

  useEffect(() => {
    // update local storage value
    if (sortedList.length) {
      window.sessionStorage.setItem(
        'commit',
        JSON.stringify(
          sortedList.map((proposal) => proposal.trustee.toString())
        )
      );
    }
  }, [sortedList]);

  return (
    <div style={{ margin: 48 }}>
      <form onSubmit={commitVote}>
        <Row justify="space-around" align="top" gutter={[24, 32]}>
          <Col
            md={{ span: 20, order: 1 }}
            lg={{ span: 20, order: 1 }}
            style={{ textAlign: 'left' }}
          >
            <Typography.Title level={3}>
              New Vote
              <InfoBubble text="A commit file will be available to download containing the unique seed used to generate your encrypted vote hash, as well as a copy of your submitted ranking. To reveal your vote, upload the file during the reveal stage." />
            </Typography.Title>
            <Typography.Paragraph>
              To submit a vote, order the following proposals from most
              preferred on the top, to least preferred on the bottom
            </Typography.Paragraph>
          </Col>
          <Col md={{ span: 4, order: 2 }} lg={{ span: 4, order: 2 }}>
            <Button
              type="primary"
              size="large"
              onClick={() => setSubmitOpen(true)}
              disabled={gov.stage !== GovernanceStage.Commit}
            >
              Commit Vote
            </Button>
          </Col>
        </Row>

        <Divider />

        <Row justify="space-around" align="top" gutter={[24, 32]}>
          <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
            <ProposalTable
              list={sortedList}
              setList={setSortedList}
              ranked
              signer={signer}
            />
          </Col>
          {gov.stage === GovernanceStage.Commit && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
              <Typography.Title level={4} style={{ textAlign: 'left' }}>
                Committed Trustees
              </Typography.Title>
              <CommitList />
            </Col>
          )}
        </Row>
      </form>
      <Modal
        visible={submitOpen}
        title="Vote Submit Confirmation"
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={commitVote}
            loading={processing}
          >
            Commit Vote
          </Button>,
          <Button key="cancel" onClick={() => setSubmitOpen(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Typography.Title level={5}>You are about to commit.</Typography.Title>
        <Typography.Text>
          Once the transaction is submitted, please do not leave this page, your
          commit file will be available once the transaction has completed
          successfully.
        </Typography.Text>
      </Modal>
      <Modal
        visible={successOpen}
        title="Save/Download your Commit"
        closable={false}
        maskClosable={false}
        footer={[
          <Button key="download" type="primary" onClick={downloadCommit}>
            Download Commit as File
          </Button>,
          <Button key="close" onClick={onModalClose}>
            Close
          </Button>,
        ]}
      >
        <Typography.Title level={5}>Encrypted Vote:</Typography.Title>
        <Typography.Text>{hash}</Typography.Text>
      </Modal>
    </div>
  );
}
