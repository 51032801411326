import { Row, Col, Typography } from 'antd';

import VoteList from '../components/VoteList';

import { useGovernance } from '../providers/GovernanceProvider';
import { usePastGenerationResults } from '../hooks';
import { useTrustees } from '../providers/TrusteeProvider';
import GenerationResults from '../components/GenerationResults';

/**
 * Execute view
 *
 * Shows winning proposal and non-winning proposals during Execute stage (Compute and Finished)
 *
 */

export default function Execute({ signer }) {
  const gov = useGovernance();
  const trustees = useTrustees();
  const pastGenerations = usePastGenerationResults(1);

  const lastGeneration = pastGenerations.length ? pastGenerations[0] : null;

  const winningProposal = gov.winner
    ? gov.proposals.find((proposal) => proposal.trustee.eq(gov.winner.trustee))
    : null;

  const losingProposals = gov.proposals.filter(
    (proposal) => !proposal.trustee.eq(gov.winner?.trustee)
  );

  return (
    <div style={{ margin: 32 }}>
      <div>
        {gov.winner ? (
          <div>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              Current Generation's Results
            </Typography.Title>
            <GenerationResults
              winningProposal={winningProposal}
              losingProposals={losingProposals}
              scores={gov.scores}
              maxScore={gov.proposals.length * trustees.length}
              signer={signer}
            />
          </div>
        ) : (
          <Typography.Title level={2}>
            Wait for Computation of winning proposal
          </Typography.Title>
        )}
        <br />
        <Row justify="space-around" align="top" gutter={[24, 32]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={12}>
            <Typography.Title level={3} style={{ textAlign: 'left' }}>
              Trustee Votes
            </Typography.Title>
            <VoteList />
          </Col>
        </Row>
        <br />
        <br />
        {lastGeneration ? (
          <div>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              Last Generation's Results
            </Typography.Title>
            <GenerationResults
              winningProposal={lastGeneration.winningProposal}
              losingProposals={lastGeneration.proposals.filter(
                (proposal) =>
                  !proposal.trustee.eq(lastGeneration.winningProposal.trustee)
              )}
              scores={lastGeneration.scores}
              maxScore={
                lastGeneration.proposals.length * lastGeneration.trustees.length
              }
              signer={signer}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
