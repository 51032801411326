import { Typography } from 'antd';
import { toast } from 'react-toastify';
import download from './download';

export default function toastTxError({ txHash, message, err }) {
  if (txHash && global.env.desiredNetwork.blockExplorer) {
    toast.error(
      <div style={{ textAlign: 'center' }}>
        <Typography.Paragraph>{message}</Typography.Paragraph>
        <Typography.Link
          href={global.env.desiredNetwork.blockExplorer + '/tx/' + txHash}
        >
          View on block explorer
        </Typography.Link>
        {err && (
          <>
            <br />
            <Typography.Link
              onClick={() => download('stacktrace.txt', err.stack.toString())}
            >
              Download stack trace
            </Typography.Link>
          </>
        )}
      </div>,
      { autoClose: 15_000 }
    );
  } else {
    toast.error(message);
  }
}
