import { BigNumber, utils } from 'ethers';

// 1e18
const defaultInflation = BigNumber.from('1000000000000000000');

const secondsPerDay = 86400;

// convert a whole proposal from display friendly attributes to tx safe parameters (BigNumbers)
export default function proposalToOnChain(proposal, totalSupply) {
  let convertedProposal = { ...proposal };

  convertedProposal.numberOfRecipients = numberOfRecipientsDisplayToOnChain(
    proposal.numberOfRecipients
  );

  convertedProposal.randomInflationReward =
    proposal.numberOfRecipients > 0
      ? randomInflationRewardDisplayToOnChain(
          proposal.randomInflationReward,
          totalSupply
        ).div(proposal.numberOfRecipients)
      : BigNumber.from(0);

  convertedProposal.lockupInterest = lockupInterestDisplayToOnChain(
    ((proposal.lockupInterest / 365) * proposal.lockupDuration).toFixed(7)
  );

  convertedProposal.lockupDuration = lockupDurationDisplayToOnChain(
    proposal.lockupDuration
  );

  convertedProposal.inflationMultiplier = multiplierDisplayToOnChain(
    proposal.inflationMultiplier
  );

  return convertedProposal;
}

// convert random inflation prize from display value to sol parameter (convert from percent of totalSupply to number, appends decimals, returns BigNumber)
export function randomInflationRewardDisplayToOnChain(
  displayValue,
  totalSupply
) {
  return utils.parseUnits(
    ((displayValue / 100) * utils.formatUnits(totalSupply, 18)).toString(),
    18
  );
}

// number of recipients that will receive a random inflation prize.
export function numberOfRecipientsDisplayToOnChain(displayValue) {
  return BigNumber.from(Math.round(displayValue));
}

// convert lockup duration from days to seconds
export function lockupDurationDisplayToOnChain(displayValue) {
  return BigNumber.from(Math.round(displayValue * secondsPerDay));
}

// add decimals to percecntage (1_000_000_000 == 100%)
export function lockupInterestDisplayToOnChain(displayValue) {
  return utils.parseUnits(displayValue.toString(), 7);
}

// convert inflation multiplier display friendly value to sol parameter
export function multiplierDisplayToOnChain(displayValue) {
  var onChainValue = defaultInflation;
  if (displayValue !== 0) {
    onChainValue = utils.parseUnits((100 / (100 + displayValue)).toString());
  }
  return onChainValue;
}
