import { useErc20 } from '../providers/Erc20Provider';
import { utils } from 'ethers';

import { Statistic, Button } from 'antd';

// to prevent global.env from warning
const globalAny: any = global;

/**
 * TotalSupply component
 *
 * props:
 * - small: bool
 */

type TotalSupplyProps = {
  small: boolean;
};

export default function TotalSupply({ small = false }: TotalSupplyProps) {
  const erc20 = useErc20();

  // parse totalSupply to readable format
  const totalSupply = parseInt(utils.formatUnits(erc20.totalSupply, 18));

  return small ? (
    <Button
      type="text"
      key="supply"
      href={
        globalAny.env.desiredNetwork.blockExplorer + '/token/' + erc20.address
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      ECO SUPPLY: {totalSupply.toLocaleString()}
    </Button>
  ) : (
    <Statistic title="ECO Supply" value={totalSupply} />
  );
}
