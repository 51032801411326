import React from 'react';

import { Radio, Button } from 'antd';
import Address from './Address';

/**
 * Account component
 *
 * Displays your address, allows connect/disconnect
 *
 */

export default function Account({
  address,
  mainnetProvider,
  minimized,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  blockExplorer,
}) {
  const modalButtons = [];

  // connect or disconnect
  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <Radio.Button key="logoutbutton" onClick={logoutOfWeb3Modal}>
          Disconnect
        </Radio.Button>
      );
    } else {
      return (
        <Button
          size="large"
          type="primary"
          key="loginbutton"
          onClick={loadWeb3Modal}
        >
          Connect Wallet
        </Button>
      );
    }
  }

  // address display
  const display = !minimized ? (
    <Radio.Button disabled={!address}>
      {address ? (
        <Address
          short
          address={address}
          ensProvider={mainnetProvider}
          blockExplorer={blockExplorer}
        />
      ) : (
        'Connecting...'
      )}
    </Radio.Button>
  ) : null;

  return (
    <Radio.Group size="large">
      {display}
      {modalButtons}
    </Radio.Group>
  );
}
