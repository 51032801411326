import { useEffect, useRef, useState } from 'react';

import { Col, Row, Typography, Steps } from 'antd';
import Countdown from 'react-countdown';

import { useLocation, useNavigate } from 'react-router-dom';

import { useGovernance } from '../providers/GovernanceProvider';

const navStages = [
  {
    path: '/',
    display: 'Propose',
  },
  {
    path: '/vote',
    display: 'Commit',
  },
  {
    path: '/reveal',
    display: 'Reveal',
  },
  {
    path: '/execute',
    display: 'Execute',
  },
];

/**
 * StageDisplay
 *
 * Displays the current stage and countdown until the next
 *
 * props available:
 * - desiredStage (shows only when stage specified is active)
 * - noCountdown (doesn't display the countdown)
 * - noStage (doesn't display the stage)
 * - countdownPrefix (custom prefix text before the countdown)
 * - countdownSuffix (same as prefix but after)
 */

function StageDisplay({
  desiredStage,
  noCountdown,
  noStage,
  countdownSuffix = '',
}) {
  const gov = useGovernance();
  const navigate = useNavigate();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(
    navStages.findIndex((stage) => stage.path === location.pathname)
  );

  const ctdown = useRef(null);

  useEffect(() => {
    // call start (autostart countdown after time is changed)
    ctdown.current?.start();
  }, [gov.stageEnds]);

  const onChange = (value) => {
    navigate(navStages[value].path);
  };

  useEffect(() => {
    
      setCurrentPath(
        navStages.findIndex((stage) => stage.path === location.pathname)
      );
    
  }, [location.pathname]);

  const stageName = navStages[gov.stage]?.display.toLowerCase();

  return !desiredStage || gov.stage === desiredStage ? (
    <Row
      justify="start"
      align="middle"
      style={{ padding: '12px 0' }}
      gutter={[12, 16]}
    >
      {!noStage && (
        <Col xs={24} sm={12} md={12}>
          <Steps
            type="navigation"
            size="small"
            responsive
            onChange={onChange}
            current={currentPath}
          >
            <Steps.Step
              title="Propose"
              status={
                gov.stage === 0 ? 'process' : gov.stage > 0 ? 'finish' : 'wait'
              }
            />
            <Steps.Step
              title="Commit"
              status={
                gov.stage === 1 ? 'process' : gov.stage > 1 ? 'finish' : 'wait'
              }
            />
            <Steps.Step
              title="Reveal"
              status={
                gov.stage === 2 ? 'process' : gov.stage > 2 ? 'finish' : 'wait'
              }
            />
            <Steps.Step
              title="Execute"
              status={gov.stage >= 3 ? 'process' : 'wait'}
            />
          </Steps>
        </Col>
      )}
      {!noCountdown && gov.stageEnds ? (
        <Col sx={24} sm={12} md={12}>
          <Countdown
            ref={ctdown}
            date={gov.stageEnds * 1000}
            renderer={({ days, hours, minutes, seconds, completed }) =>
              completed ? null : (
                <Typography.Text
                  type="success"
                  style={{
                    padding: '24px',
                    fontSize: '16px',
                    lineHeight: 0.8,
                  }}
                >{`Time left to ${stageName}: ${days ? `${days} days,` : ''} ${
                  hours ? `${hours} hours,` : ''
                } ${
                  minutes ? `${minutes} minutes,` : ''
                } ${seconds} seconds ${countdownSuffix}`}</Typography.Text>
              )
            }
          />
        </Col>
      ) : null}
    </Row>
  ) : null;
}

export default StageDisplay;
