import { Row, Col, Typography } from 'antd';
import ProposalTable from '../components/ProposalTable';

import { useGovernance } from '../providers/GovernanceProvider';
import TotalSupply from '../components/TotalSupply';
import { useTrustees } from '../providers/TrusteeProvider';

/**
 * Static view
 *
 * shows current stage, dune analytics embeds, and the verbose list of proposals
 */

function Static({ signer }) {
  const gov = useGovernance();
  const trustees = useTrustees();

  const winningProposal = gov.winner
    ? gov.proposals.find((proposal) => proposal.trustee.eq(gov.winner.trustee))
    : null;

  return (
    <div style={{ margin: 32 }}>
      <Row justify="space-around" align="top" gutter={[24, 32]}>
        <Col xs={12} sm={8} md={4}>
          <TotalSupply />
        </Col>
      </Row>
      <br />
      <Row justify="space-around" align="top" gutter={[24, 32]}>
        {gov.winner && (
          <Col xs={24} sm={24} md={16} lg={14} xl={12}>
            <Typography.Title level={3} style={{ textAlign: 'left' }}>
              Winning Proposal
            </Typography.Title>
            <ProposalTable
              list={winningProposal ? [winningProposal] : []}
              scores={gov.scores}
              maxScore={gov.proposals.length * trustees.length}
              signer={signer}
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={16} lg={14} xl={12}>
          <iframe
            title="analytics"
            src="https://dune.com/embeds/4962/9776/e1b3cfbe-104a-4944-8a22-b4b7bb8cdd14"
            width="100%"
            height="400px"
          />
        </Col>
      </Row>
    </div>
  );
}

export default Static;
