import { ethers, BigNumber } from 'ethers';
import { ReactChild, createContext, useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ethProvider } from '../../constants';
import { currTime } from '../../helpers';
import { useContractAddresses } from '../ContractAddressProvider';
import { useTrustees } from '../TrusteeProvider';

import { isNullAddress, NULL_ADDRESS } from '../../helpers';

import {
  GovernanceInterface,
  GovernanceProposal,
  GovernanceStage,
  Commit,
  Vote,
  Score,
  Address,
} from '../../types';

import timedPoliciesABI from '../../assets/abi/TimedPolicies.json';
import currencyGovernanceABI from '../../assets/abi/CurrencyGovernance.json';

/**
 * GovProvider
 *
 * Maintains governance state for the lifecycle of the app, such as:
 * stage: the current stage of the vote, with 'name' and 'endsAt' attributes
 * proposals: the list of proposals
 * commits: a list of the trustees who have committed
 * scores: a list of trustees and what score their proposal has (during voting)
 * votes: a list of trustees and the array of trustees whose proposals they voted for
 */

// stage enum
export const Stage = {
  0: 'Propose',
  1: 'Commit',
  2: 'Reveal',
  3: 'Compute',
  4: 'Finished',
};

const DEFAULT_PROPOSAL: GovernanceProposal = {
  trustee: new Address(NULL_ADDRESS),
  numberOfRecipients: BigNumber.from(0),
  randomInflationReward: BigNumber.from(0),
  lockupDuration: BigNumber.from(0),
  lockupInterest: BigNumber.from(0),
  inflationMultiplier: BigNumber.from('1000000000000000000'),
  description: 'The default proposal makes no changes',
};

export const InfuraGovernanceContext = createContext<GovernanceInterface>({
  proposals: [],
  stage: GovernanceStage.Finished,
  stageEnds: null,
  commits: [],
  votes: [],
  scores: [],
  winner: null,
  nextGenerationStartsAt: null,
});

type GovernanceProviderProps = {
  children: ReactChild;
};

export default function InfuraGovernanceProvider({
  children,
}: GovernanceProviderProps) {
  const contracts = useContractAddresses();
  const trustees = useTrustees();

  const [stage, setStage] = useState<GovernanceStage>(GovernanceStage.Finished);
  const [stageEnds, setStageEnds] = useState<number | null>(null);

  const [proposals, setProposals] = useState<GovernanceProposal[]>([
    DEFAULT_PROPOSAL,
  ]);
  const [votes, setVotes] = useState<Vote[]>([]);
  const [scores, setScores] = useState<Score[]>([]);
  const [commits, setCommits] = useState<Commit[]>([]);
  const [winner, setWinner] = useState<GovernanceProposal | null>(null);

  // when currencyGovernance contract updates, update the contract object to that new address
  const currencyGovernance = useMemo(() => {
    let result = null;
    if (contracts.currencyGovernance) {
      result = new ethers.Contract(
        contracts.currencyGovernance.toString(),
        currencyGovernanceABI.abi,
        ethProvider()
      );
    }
    return result;
  }, [contracts.currencyGovernance]);

  const [nextGenerationStartsAt, setNextGenerationStartsAt] = useState<
    number | null
  >(null);

  // listen for updates on proposals (created, removed)

  // when stage updates, update stageEnds
  useEffect(() => {
    // timer for next stage (if applicable)
    let nextStageTimer: NodeJS.Timeout | null = null;

    async function updateStageEnds() {
      if (!currencyGovernance) {
        return;
      }

      let ends = null;
      let nextStage: GovernanceStage = GovernanceStage.Finished; // next stage enum index

      try {
        // get stageEnds if necessary
        switch (stage) {
          case GovernanceStage.Propose:
            // propose
            ends = await currencyGovernance.proposalEnds();
            nextStage = GovernanceStage.Commit;
            break;
          case GovernanceStage.Commit:
            // commit
            ends = await currencyGovernance.votingEnds();
            nextStage = GovernanceStage.Reveal;
            break;
          case GovernanceStage.Reveal:
            // reveal
            ends = await currencyGovernance.revealEnds();
            nextStage = GovernanceStage.Compute;
            break;
          case GovernanceStage.Compute:
            // compute
            break;
          case GovernanceStage.Finished:
            // finished
            break;
        }

        if (ends) {
          // stage could already be over but not incremented on chain
          const timeUntilNextStage = ends.toNumber() - currTime();

          if (timeUntilNextStage <= 0) {
            // set next stage preemptively for UI,
            // when the next tx is called in currencyGovernance, atStage should update the stage to the correct one without error

            setStage(nextStage);
          } else {
            // set the next stage when the time comes
            nextStageTimer = setTimeout(
              () => setStage(nextStage),
              Math.round(timeUntilNextStage * 1000)
            );
          }
          // convert to unix milliseconds
          setStageEnds(ends.toNumber());
        } else {
          setStageEnds(null);
        }
      } catch (err) {
        console.log(err);
      }
    }

    console.log('new stage: ', stage);
    updateStageEnds();

    return () => {
      if (nextStageTimer) {
        clearTimeout(nextStageTimer);
      }
    };
  }, [stage]);

  // find proposal for a trustee
  async function getProposal(
    trustee: Address
  ): Promise<GovernanceProposal | null> {
    let result = null;
    if (currencyGovernance) {
      try {
        const proposal = await currencyGovernance.proposals(trustee.toString());

        if (proposal.inflationMultiplier.gt(0)) {
          result = {
            trustee: trustee,
            numberOfRecipients: proposal.numberOfRecipients,
            randomInflationReward: proposal.randomInflationReward,
            lockupDuration: proposal.lockupDuration,
            lockupInterest: proposal.lockupInterest,
            inflationMultiplier: proposal.inflationMultiplier,
            description: proposal.description,
          };
        }
      } catch (err) {
        console.log(err);
      }
    }
    return result;
  }

  async function getVotes() {
    if (!currencyGovernance) {
      return;
    }
    try {
      // parse past events for VoteReveal
      const voteFilter = currencyGovernance.filters.VoteReveal(null, null);

      const start = Date.now();
      const txes = await currencyGovernance.queryFilter(voteFilter);
      const end = Date.now();

      console.log(`Took: ${end - start} ms`);
      console.log(txes);

      // for each vote cast
      for (var i = 0; i < txes.length; i++) {
        if (txes[i].args) {
          let voter: string = txes[i].args![0];
          let rankedProposals: string[] = txes[i].args![1];

          // save vote
          setVotes((votes) => [
            ...votes.filter((vt) => !vt.trustee.eq(voter)),
            {
              trustee: new Address(voter),
              rankedProposals: rankedProposals.map(
                (proposal) => new Address(proposal)
              ),
            },
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getCommit(trustee: Address): Promise<Commit | null> {
    let result = null;
    if (currencyGovernance) {
      try {
        // get proposals for every trustee
        for (var i = 0; i < trustees.length; i++) {
          // get commit status
          const commit = await currencyGovernance.commitments(
            trustee.toString()
          );
          if (
            commit !==
            '0x0000000000000000000000000000000000000000000000000000000000000000'
          ) {
            result = {
              trustee,
            };
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    return result;
  }

  async function getWinner() {
    if (!currencyGovernance) {
      return;
    }
    try {
      const winningTrustee = await currencyGovernance.winner();

      if (!isNullAddress(winningTrustee)) {
        const winningProposal = await getProposal(winningTrustee);

        if (winningProposal) {
          setWinner(winningProposal);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  // listen for stage updates
  useEffect(() => {
    // reset proposals
    setProposals([DEFAULT_PROPOSAL]);
    setVotes([]);
    setWinner(null);

    async function getStage() {
      if (!currencyGovernance) {
        return;
      }
      try {
        const stg: GovernanceStage = await currencyGovernance.currentStage();
        setStage(stg);
      } catch (err) {
        console.log(err);
      }
    }

    async function getProposals() {
      try {
        // get proposals for every trustee
        for (var i = 0; i < trustees.length; i++) {
          // get proposal
          let proposal = await getProposal(trustees[i]);

          // if proposal is valid (exists), add it
          if (proposal) {
            setProposals((prpsls) => [
              ...prpsls.filter((prpsl) => !prpsl.trustee.eq(trustees[i])),
              proposal!,
            ]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    const getNextGenerationStart = async () => {
      if (!contracts.timedPolicies) {
        return;
      }
      try {
        const timedPoliciesContract = new ethers.Contract(
          contracts.timedPolicies.toString(),
          timedPoliciesABI.abi,
          ethProvider()
        );

        const nextGenerationStart =
          await timedPoliciesContract.nextGenerationStart();

        setNextGenerationStartsAt(nextGenerationStart.toNumber());
      } catch (err) {
        toast({
          title: 'Failed to get next generation start time',
          intent: 'danger',
        });
        console.log(err);
      }
    };

    if (currencyGovernance) {
      // check the next generation start
      getNextGenerationStart();

      getStage();

      getProposals();
      getVotes();
      getWinner();

      // listen for stage updates (not needed if we preemtively assign stage)
      // currencyGovernance.on('VoteStart', () => setStage(GovernanceStage.Commit));
      // currencyGovernance.on('RevealStart', () => setStage(GovernanceStage.Reveal));

      // listen for proposals added
      currencyGovernance.on(
        'ProposalCreation',
        (
          trustee,
          numberOfRecipients,
          randomInflationReward,
          lockupDuration,
          lockupInterest,
          inflationMultiplier,
          description
        ) => {
          const newProposal: GovernanceProposal = {
            trustee: new Address(trustee),
            numberOfRecipients: numberOfRecipients,
            randomInflationReward: randomInflationReward,
            lockupDuration: lockupDuration,
            lockupInterest: lockupInterest,
            inflationMultiplier: inflationMultiplier,
            description,
          };

          // check if this is an ammendment proposal and replace (remove old and append new) if so
          setProposals((prpsls) => [
            ...prpsls.filter((prpsl) => !prpsl.trustee.eq(trustee)),
            newProposal,
          ]);
        }
      );

      currencyGovernance.on('ProposalRetraction', (trustee: string) => {
        // remove proposal
        setProposals((prpsls) =>
          prpsls.filter((prpsl) => !prpsl.trustee.eq(trustee))
        );
      });

      currencyGovernance.on('VoteCast', (trustee: string) => {
        toast.info('A trustee has made a commitment!' + trustee);

        // append without generating duplicate
        setCommits((cmts) => [
          ...cmts.filter((cmt) => !cmt.trustee.eq(trustee)),
          { trustee: new Address(trustee) },
        ]);
      });

      currencyGovernance.on('VoteReveal', (voter: string, votes: string[]) => {
        // voter: address of voting trustee
        // vote: array of trustee addresses that the voter has voted for

        toast.info('A Trustee has revealed their vote!');

        setVotes((vts) => [
          ...vts.filter((vt) => !vt.trustee.eq(voter)),
          {
            trustee: new Address(voter),
            rankedProposals: votes.map((vote) => new Address(vote)),
          },
        ]);

        // remove committed trustee
        setCommits((cmts) => cmts.filter((cmt) => !cmt.trustee.eq(voter)));
      });

      // listen for finished stage (no timer)
      currencyGovernance.on('VoteResult', (winningTrustee: string) => {
        console.log('Winning Trustee:', winningTrustee);

        if (!isNullAddress(winningTrustee)) {
          toast.info(
            `Vote Results have been determined!\nTrustee of Winning Proposal:\n${winningTrustee}`
          );

          getProposal(new Address(winningTrustee)).then((winningProposal) => {
            if (winningProposal) {
              setWinner(winningProposal);
            }
          });
        } else {
          toast.info(
            `Vote is over!\nNo proposal will be enacted for this generation`
          );
        }

        setStage(GovernanceStage.Finished);
      });
    }

    return () => {
      if (currencyGovernance) {
        currencyGovernance.removeAllListeners();
      }
    };
  }, [currencyGovernance]);

  useEffect(() => {
    function calculateScores() {
      // create initial array with default proposal
      let localScores: Score[] = [
        {
          trustee: new Address(NULL_ADDRESS),
          score: trustees.length - votes.length,
        },
      ];

      // for each vote cast
      for (var i = 0; i < votes.length; i++) {
        let rankedProposals = votes[i].rankedProposals;

        // iterate and accumulate scores
        for (var j = 0; j < rankedProposals.length; j++) {
          // add trustee from vote to scores if not yet encountered
          if (!localScores.find((sc) => sc.trustee.eq(rankedProposals[j]))) {
            localScores.push({
              trustee: rankedProposals[j],
              score: 0,
            });
          }

          // remove one from the default score

          localScores = localScores.map((sc) => {
            if (rankedProposals[j].eq(sc.trustee)) {
              sc.score += rankedProposals.length - j;
            }
            return sc;
          });
        }
      }

      // save scores
      setScores(localScores);
    }

    calculateScores();
  }, [votes, trustees.length]);

  const [prevTrustees, setPrevTrustees] = useState(trustees);

  useEffect(() => {
    // if trustees were deleted, remove the proposals for those trustees if any exist
    if (prevTrustees.length > trustees.length) {
      const removedTrustees = prevTrustees.filter(
        (trustee) => !trustees.includes(trustee)
      );
      console.log('removed trustees:', removedTrustees);

      const newProposals = proposals.filter(
        (prpsl) =>
          !removedTrustees.some((removedTrustee) =>
            removedTrustee.eq(prpsl.trustee)
          )
      );

      setProposals(newProposals);

      const newVotes = votes.filter(
        (vt) =>
          !removedTrustees.some((removedTrustee) =>
            removedTrustee.eq(vt.trustee)
          )
      );

      setVotes(newVotes);
    } else if (prevTrustees.length < trustees.length) {
      // trustees added, see if there are proposals for new trustees

      const addedTrustees = trustees.filter(
        (trustee) => !prevTrustees.includes(trustee)
      );
      console.log('added trustees:', addedTrustees);

      // add new proposals and scores if exist
      for (var i = 0; i < addedTrustees.length; i++) {
        getProposal(addedTrustees[i]).then((newProposal) => {
          if (newProposal) {
            // append while checking for duplicates
            setProposals((prpsls) => [
              ...prpsls.filter(
                (prpsl) => !prpsl.trustee.eq(newProposal.trustee)
              ),
              newProposal,
            ]);
          }
        });

        // get commit
        getCommit(addedTrustees[i]).then((commit) => {
          if (commit) {
            setCommits((commits) => [
              ...commits.filter((cmt) => !cmt.trustee.eq(commit.trustee)),
              commit,
            ]);
          }
        });
      }
    }
    setPrevTrustees(trustees);
  }, [trustees.length]);

  return (
    <InfuraGovernanceContext.Provider
      value={{
        stage,
        stageEnds,
        proposals,
        votes,
        scores,
        commits,
        winner,
        nextGenerationStartsAt,
      }}
    >
      {children}
    </InfuraGovernanceContext.Provider>
  );
}
