import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

function InfoBubble({
  text,
  width,
}: {
  text: string;
  width: number | string | undefined;
}) {
  return (
    <Tooltip title={text} overlayStyle={{ maxWidth: '400px' }}>
      <InfoCircleOutlined style={{ width: width, margin: '0px 8px' }} />
    </Tooltip>
  );
}
export default InfoBubble;
