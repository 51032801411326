import { useState, useEffect } from 'react';

import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Divider,
  Radio,
  List,
} from 'antd';

import { toast } from 'react-toastify';
import { ethers, utils } from 'ethers';

import ProposalTable from '../components/ProposalTable';
import TotalSupply from '../components/TotalSupply';

import { useContractAddresses } from '../providers/ContractAddressProvider';
import { useGovernance } from '../providers/GovernanceProvider';
import { useErc20 } from '../providers/Erc20Provider';

import {
  proposalToOnChain,
  proposalToDisplay,
  toastTxError,
  limitDecimals,
  formatNumber,
  percentToNumberOf,
} from '../helpers';

import currencyGovernanceABI from '../assets/abi/CurrencyGovernance.json';

import { GovernanceStage } from '../types';
import moment from 'moment';
import InfoBubble from '../components/InfoBubble';

// for inputs with additional info beside
function HalfInput({ left, right }) {
  return (
    <div style={{ display: 'table', paddingTop: '8px', width: '100%' }}>
      <div
        style={{
          width: '50%',
          paddingRight: '4px',
          display: 'table-cell',
        }}
      >
        {left}
      </div>
      <div
        style={{
          width: '50%',
          paddingLeft: '4px',
          display: 'table-cell',
        }}
      >
        {right}
      </div>
    </div>
  );
}

/**
 * Propose view
 *
 * Allows an admin to fill a form and submit a proposal
 */

export default function Propose({ signer }) {
  const contracts = useContractAddresses();
  const gov = useGovernance();
  const erc20 = useErc20();

  const [numberOfRecipients, setnumberOfRecipients] = useState(null);
  const [randomInflationReward, setrandomInflationReward] = useState(null);
  const [lockupDuration, setLockupDuration] = useState(90);
  const [lockupInterest, setLockupInterest] = useState(null);
  const [multiplier, setMultiplier] = useState(null);
  const [description, setDescription] = useState('');

  // 'inflate' for inflation, 'deflate' for deflation
  const [inflate, setInflate] = useState('inflate');

  const [alreadyProposed, setAlreadyProposed] = useState(false);
  const [editing, setEditing] = useState(false);

  const [myAddress, setMyAddress] = useState(null);

  function resetInputs() {
    setnumberOfRecipients('');
    setrandomInflationReward('');
    setLockupDuration(90);
    setLockupInterest('');
    setMultiplier('');
    setDescription('');
    setInflate('inflate');
  }

  // check if number is valid with limits
  function numberIsInRangeInclusive(val, { lower = null, upper = null }) {
    return (lower === null || val >= lower) && (upper === null || val <= upper);
  }

  function numberIsInRangeExclusive(val, { lower = null, upper = null }) {
    return (lower === null || val > lower) && (upper === null || val < upper);
  }

  // checks validity of inputs, returns false if one or more are invalid
  function checkProposalInputs(proposal) {
    let valid = true;

    // check numbers are in range
    if (
      !proposal.inflationMultiplier.toString() ||
      isNaN(proposal.inflationMultiplier) ||
      !numberIsInRangeExclusive(proposal.inflationMultiplier, { lower: -100 })
    ) {
      toast.error('Linear supply change is out of range');
      valid = false;
    }
    if (
      !proposal.randomInflationReward.toString() ||
      isNaN(proposal.randomInflationReward) ||
      !numberIsInRangeInclusive(proposal.randomInflationReward, {
        lower: 0,
        upper: 100,
      })
    ) {
      toast.error('Random inflation amount is out of range');
      valid = false;
    }
    if (
      !proposal.numberOfRecipients.toString() ||
      isNaN(proposal.numberOfRecipients) ||
      !numberIsInRangeInclusive(proposal.numberOfRecipients, { lower: 0 })
    ) {
      toast.error('Number of tickets is out of range');
      valid = false;
    }
    if (
      !proposal.lockupDuration.toString() ||
      isNaN(proposal.lockupDuration) ||
      (proposal.lockupDuration !== 0 &&
        !numberIsInRangeInclusive(proposal.lockupDuration, { lower: 7 }))
    ) {
      toast.error(
        'Lockup duration must be either 0 (no lockup) or at least 7 days'
      );
      valid = false;
    }
    if (
      !proposal.lockupInterest.toString() ||
      isNaN(proposal.lockupInterest) ||
      !numberIsInRangeInclusive(proposal.lockupInterest, { lower: 0 })
    ) {
      toast.error('Lockup interest cannot be less than 0');
      valid = false;
    }

    // numbers are not 0 when their partner values aren't
    if (proposal.lockupDuration === 0 && proposal.lockupInterest > 0) {
      toast.error(
        'Lockup interest cannot be more than 0 if lockup duration is 0'
      );
      valid = false;
    }
    if (proposal.lockupDuration > 0 && proposal.lockupInterest === 0) {
      toast.error(
        'Lockup duration cannot be more than 0 if lockup interest is 0'
      );
      valid = false;
    }

    if (
      proposal.numberOfRecipients === 0 &&
      proposal.randomInflationReward > 0
    ) {
      toast.error(
        'Random infaltion amount cannot be more than 0 if number of tickets is 0'
      );
      valid = false;
    }
    if (
      proposal.numberOfRecipients > 0 &&
      proposal.randomInflationReward === 0
    ) {
      toast.error(
        'Number of tickets cannot be more than 0 if random inflation amount is 0'
      );
      valid = false;
    }

    return valid;
  }

  async function submitProposal(e) {
    if (e) {
      e.preventDefault();
    }

    if (!signer) {
      toast.error('Please Connect a wallet to Submit a Proposal');
      return;
    }

    const myProposal = {
      inflationMultiplier: parseFloat(
        inflate === 'inflate' ? multiplier : 0 - multiplier
      ),
      randomInflationReward: parseFloat(randomInflationReward),
      numberOfRecipients: parseFloat(numberOfRecipients),
      lockupDuration: parseFloat(lockupDuration),
      lockupInterest: parseFloat(lockupInterest),
      description,
    };

    console.log(myProposal);

    // check all params are valid
    if (!checkProposalInputs(myProposal)) {
      return;
    }

    const convertedProposal = proposalToOnChain(myProposal, erc20.totalSupply);
    console.log(convertedProposal);

    let submitTx;
    try {
      const currencyGovernance = new ethers.Contract(
        contracts.currencyGovernance.toString(),
        currencyGovernanceABI.abi,
        signer
      );

      submitTx = await currencyGovernance.propose(
        convertedProposal.numberOfRecipients,
        convertedProposal.randomInflationReward,
        convertedProposal.lockupDuration,
        convertedProposal.lockupInterest,
        convertedProposal.inflationMultiplier,
        convertedProposal.description,
        { gasLimit: 500_000 }
      );

      const submitted = await submitTx.wait();

      if (!submitted.status) {
        console.log(submitted);
        throw new Error('Error submitting proposal');
      }

      toast.success('Proposal Submission Success');
      setEditing(false);
    } catch (err) {
      console.log(err, submitTx);
      toastTxError({
        txHash: submitTx?.hash,
        message: 'Failed to submit proposal',
        err,
      });
    }
  }

  useEffect(() => {
    // check if wallet has proposed
    async function checkProposed() {
      try {
        const address = await signer.getAddress();
        setMyAddress(address);

        const index = gov.proposals.findIndex((prop) =>
          prop.trustee.eq(address)
        );

        if (index >= 0) {
          if (!alreadyProposed) {
            // convert my proposals values to display
            console.log(gov.proposals[index]);
            const myProposal = proposalToDisplay(
              { ...gov.proposals[index] },
              erc20.totalSupply
            );

            // set inputs to your current proposal's values
            setnumberOfRecipients(myProposal.numberOfRecipients);
            setrandomInflationReward(
              limitDecimals(myProposal.randomInflationReward, 5)
            );
            setLockupDuration(myProposal.lockupDuration);
            setLockupInterest(myProposal.lockupInterest);
            setMultiplier(Math.abs(myProposal.inflationMultiplier));
            setDescription(myProposal.description);
            setInflate(
              myProposal.inflationMultiplier >= 0 ? 'inflate' : 'deflate'
            );

            setAlreadyProposed(true);
          }
        } else {
          setAlreadyProposed(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (signer && gov.stage === GovernanceStage.Propose) {
      checkProposed();
    } else {
      setAlreadyProposed(false);
      setMyAddress(null);
      resetInputs();
    }
  }, [signer, gov.proposals, gov.stage]);

  async function deleteProposal() {
    let unproposeTx;

    if (!signer) {
      toast.error('Please connect a wallet to delete proposal');
    }

    try {
      const currencyGovernance = new ethers.Contract(
        contracts.currencyGovernance.toString(),
        currencyGovernanceABI.abi,
        signer
      );

      unproposeTx = await currencyGovernance.unpropose();

      const unproposed = await unproposeTx.wait();

      if (!unproposed.status) {
        throw new Error('Error removing proposal');
      }

      toast.success('Proposal deleted Successfully');
      setAlreadyProposed(false);
    } catch (err) {
      toastTxError({
        txHash: unproposeTx?.hash,
        message: 'Failed to delete proposal',
        err,
      });
    }
  }

  // update inflation value
  async function handleInflate(e) {
    setInflate(e.target.value);
  }

  return (
    <div style={{ margin: 48 }}>
      <div>
        <Row justify="space-around" align="top" gutter={[24, 32]}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Typography.Title level={3}>Create Proposal</Typography.Title>
          </Col>
        </Row>
        <Divider />
        {!alreadyProposed || editing ? (
          <Row justify="space-around" align="top" gutter={[24, 32]}>
            <Col sm={24} md={16} lg={12} xl={8}>
              <div
                style={{
                  textAlign: 'left',
                  maxWidth: '600px',
                }}
              >
                <form onSubmit={submitProposal}>
                  <Typography.Title level={4}>
                    Linear Supply Change
                    <InfoBubble text="Linear Supply change determines the amount of ECO that will be minted or burned at the next generation increment, every single fraction of ECO in existence will be factored, hence the term 'linear'" />
                  </Typography.Title>
                  <Radio.Group onChange={handleInflate} value={inflate}>
                    <Radio value={'inflate'}>Increase</Radio>
                    <Radio value={'deflate'}>Decrease</Radio>
                  </Radio.Group>
                  <br />
                  <HalfInput
                    left={
                      <>
                        <Input
                          placeholder="% ECO"
                          suffix="%"
                          value={multiplier}
                          maxLength={6}
                          status={
                            multiplier &&
                            !numberIsInRangeExclusive(
                              inflate === 'inflate'
                                ? multiplier
                                : 0 - multiplier,
                              { lower: -100 }
                            ) &&
                            'error'
                          }
                          onChange={(e) =>
                            formatNumber(e.target.value, setMultiplier)
                          }
                        />
                        <Typography.Paragraph style={{ fontSize: 12 }}>
                          % of total ECO to be minted {'('}or burned
                          {')'} for all holders
                        </Typography.Paragraph>
                      </>
                    }
                    right={
                      <Typography.Paragraph>
                        {`${percentToNumberOf(
                          multiplier,
                          utils.formatUnits(erc20.totalSupply, 18)
                        ).toLocaleString()} ECO will be ${
                          inflate === 'inflate' ? 'minted' : 'burned'
                        }`}
                      </Typography.Paragraph>
                    }
                  />

                  <Divider />

                  <Typography.Title level={4}>
                    Random Inflation
                    <InfoBubble text="Random Inflation determines an amount of ECO that will be claimable only by the number of winning events, each weiECO in existence has an equal chance of being chosen as a recipient of random inflation." />
                  </Typography.Title>
                  <HalfInput
                    left={
                      <>
                        <Input
                          placeholder="% ECO"
                          suffix="%"
                          value={randomInflationReward}
                          maxLength={7}
                          status={
                            !numberIsInRangeInclusive(randomInflationReward, {
                              lower: 0,
                              upper: 100,
                            }) && 'error'
                          }
                          onChange={(e) =>
                            formatNumber(
                              e.target.value,
                              setrandomInflationReward
                            )
                          }
                        />
                        <Typography.Paragraph style={{ fontSize: 12 }}>
                          % of ECO to be minted
                        </Typography.Paragraph>
                      </>
                    }
                    right={
                      <Typography.Paragraph>
                        {percentToNumberOf(
                          randomInflationReward,
                          utils.formatUnits(erc20.totalSupply, 18)
                        ).toLocaleString()}{' '}
                        ECO will be minted
                      </Typography.Paragraph>
                    }
                  />

                  <br />
                  <HalfInput
                    left={
                      <>
                        <Input
                          placeholder="Number of Winning Events"
                          value={numberOfRecipients}
                          status={
                            !numberIsInRangeInclusive(numberOfRecipients, {
                              lower: 0,
                            }) && 'error'
                          }
                          onChange={(e) =>
                            formatNumber(
                              e.target.value,
                              setnumberOfRecipients,
                              true
                            )
                          }
                        />
                        <Typography.Paragraph style={{ fontSize: 12 }}>
                          Number in weiECO that will be chosen to receive random
                          inflation
                        </Typography.Paragraph>
                      </>
                    }
                    right={
                      <>
                        {randomInflationReward > 0 &&
                          numberOfRecipients > 0 && (
                            <Typography.Paragraph>
                              {limitDecimals(
                                (utils.formatUnits(erc20.totalSupply) *
                                  (randomInflationReward / 100)) /
                                  numberOfRecipients
                              )}{' '}
                              ECO given to each recipient
                            </Typography.Paragraph>
                          )}
                      </>
                    }
                  />

                  <Divider />

                  <Typography.Title level={4}>
                    Interest Rate: Lockup Contracts
                    <InfoBubble text="Lockups are Vaults that anyone can deposit ECO into, and receive a percentage of their deposit as profit after the specified time period. If someone withdraws before the end of the time period, they will forfeit the amount that they would have received as profit from their initial deplosit. Lockups are created at the next generation increment." />
                  </Typography.Title>

                  <HalfInput
                    left={
                      <>
                        <Input
                          placeholder="% Interest APR"
                          suffix="%"
                          value={lockupInterest}
                          maxLength={6}
                          status={
                            !numberIsInRangeInclusive(lockupInterest, {
                              lower: 0,
                            }) && 'error'
                          }
                          onChange={(e) =>
                            formatNumber(e.target.value, setLockupInterest)
                          }
                        />
                        <Typography.Paragraph style={{ fontSize: 12 }}>
                          % APR as interest for locked up funds
                        </Typography.Paragraph>
                      </>
                    }
                    right={
                      <>
                        <Input
                          controls={false}
                          placeholder="90"
                          value={lockupDuration}
                          maxLength={5}
                          status={
                            parseInt(lockupDuration) !== 0 &&
                            !numberIsInRangeInclusive(lockupDuration, {
                              lower: 7,
                            }) &&
                            'error'
                          }
                          onChange={(e) =>
                            formatNumber(
                              e.target.value,
                              setLockupDuration,
                              true
                            )
                          }
                        />
                        <Typography.Paragraph style={{ fontSize: 12 }}>
                          Lockup Term in Days
                        </Typography.Paragraph>
                      </>
                    }
                  />
                  <br />
                  {lockupInterest > 0 && lockupDuration >= 7 ? (
                    <Typography.Paragraph>
                      Lockup deposits will yield{' '}
                      {limitDecimals((lockupInterest / 365) * lockupDuration)}%
                      after {lockupDuration || 0} days
                      {gov.nextGenerationStartsAt && (
                        <Typography.Paragraph>
                          and can vest as soon as{' '}
                          {moment(gov.nextGenerationStartsAt * 1000)
                            .add(lockupDuration, 'days')
                            .format('MMM Do YYYY')}
                        </Typography.Paragraph>
                      )}
                    </Typography.Paragraph>
                  ) : null}

                  <Divider />

                  <Typography.Title level={4}>
                    Proposal Description
                  </Typography.Title>
                  <Input
                    controls={false}
                    maxLength={255}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <Divider />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      size="large"
                      onClick={submitProposal}
                      type="primary"
                      disabled={gov.stage !== GovernanceStage.Propose}
                    >
                      {alreadyProposed ? 'Amend Proposal' : 'Propose'}
                    </Button>
                    {alreadyProposed && (
                      <Button
                        size="large"
                        onClick={deleteProposal}
                        type="primary"
                        disabled={gov.stage !== GovernanceStage.Propose}
                      >
                        Delete Proposal
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TotalSupply />
              <br />
              <div style={{ textAlign: 'left' }}>
                <Typography.Title level={3}>
                  Submitted Proposals
                </Typography.Title>
                <ProposalTable list={gov.proposals} signer={signer} />
              </div>
            </Col>
          </Row>
        ) : (
          <Row justify="space-around" align="top" gutter={[24, 32]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Typography.Title level={4} style={{ textAlign: 'left' }}>
                My Proposal
              </Typography.Title>

              <ProposalTable
                list={gov.proposals.filter((prpsl) =>
                  prpsl.trustee.eq(myAddress)
                )}
                signer={signer}
              />

              <br />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  size="large"
                  onClick={() => setEditing(true)}
                  type="primary"
                  disabled={gov.stage !== GovernanceStage.Propose}
                >
                  Edit Proposal
                </Button>
                <Button
                  size="large"
                  onClick={deleteProposal}
                  type="primary"
                  disabled={gov.stage !== GovernanceStage.Propose}
                >
                  Delete Proposal
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
