import { BigNumber } from 'ethers';
import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import { PastGenerationsQueryResult, PAST_GENERATIONS } from '../queries';
import { GovernanceProposal, Score, Address, Vote } from '../types';

import { NULL_ADDRESS } from '../helpers';
import {
  PastTrusteesQueryResult,
  PAST_TRUSTEES,
} from '../queries/PAST_TRUSTEES';
import { useContractAddresses } from '../providers/ContractAddressProvider';

type PastGenerationResult = {
  id: string;
  scores: Score[];
  votes: Vote[];
  proposals: GovernanceProposal[];
  trustees: Address[];
  winningProposal: GovernanceProposal;
};

const DEFAULT_PROPOSAL: GovernanceProposal = {
  trustee: new Address(NULL_ADDRESS),
  numberOfRecipients: BigNumber.from(0),
  randomInflationReward: BigNumber.from(0),
  lockupDuration: BigNumber.from(0),
  lockupInterest: BigNumber.from(0),
  inflationMultiplier: BigNumber.from('1000000000000000000'),
  description: 'The default proposal makes no changes',
};

/**
 * usePastGenerationResults custom hook
 *
 * returns results for the last {limit} generations
 * (ordered most recent first, and not including the current generation)
 */

const usePastGenerationResults = (limit?: number): PastGenerationResult[] => {
  const apolloClient = useApolloClient();
  const contractAddresses = useContractAddresses();

  const [pastGenerationResults, setPastGenerationResults] = useState<
    PastGenerationResult[]
  >([]);

  useEffect(() => {
    const refreshPastGenerations = async () => {
      try {
        const generationsResult =
          await apolloClient.query<PastGenerationsQueryResult>({
            query: PAST_GENERATIONS,
            variables: { limit: limit ? 1 + limit : null },
            fetchPolicy: 'no-cache',
          });

        if (generationsResult.data.generations.length > 1) {
          // generations: all past generations including current
          const generations = [...generationsResult.data.generations];
          // remove current generation from generationsResult
          generationsResult.data.generations.shift();

          const oldestGenerationBlockNumber =
            generations[generations.length - 1].blockNumber;

          const cohortsResult =
            await apolloClient.query<PastTrusteesQueryResult>({
              query: PAST_TRUSTEES(oldestGenerationBlockNumber),
              fetchPolicy: 'no-cache',
            });

          const pastGenerations = generationsResult.data.generations.map(
            (generation, index): PastGenerationResult => {
              let subgraphMonetaryProposals = generation.monetaryProposals;

              // set proposals

              let winningProposal: GovernanceProposal;

              let monetaryProposals = [
                DEFAULT_PROPOSAL,
                ...subgraphMonetaryProposals.map(
                  (proposal): GovernanceProposal => {
                    let monetaryProposal = {
                      trustee: new Address(proposal.trustee.id),
                      inflationMultiplier: BigNumber.from(
                        proposal.inflationMultiplier
                      ),
                      randomInflationReward: BigNumber.from(
                        proposal.randomInflationReward
                      ),
                      numberOfRecipients: BigNumber.from(
                        proposal.numberOfRecipients
                      ),
                      lockupDuration: BigNumber.from(proposal.lockupDuration),
                      lockupInterest: BigNumber.from(proposal.lockupInterest),
                      description: proposal.description,
                    };
                    if (proposal.enacted) {
                      winningProposal = monetaryProposal;
                    }
                    return monetaryProposal;
                  }
                ),
              ];

              let monetaryScores: Score[] = [];
              let monetaryVotes: Vote[] = [];

              if (generation.currencyGovernance) {
                let currencyGovernance = generation.currencyGovernance;

                // set scores
                monetaryScores = [
                  ...subgraphMonetaryProposals.map(
                    (proposal): Score => ({
                      trustee: new Address(proposal.trustee.id),
                      score: parseInt(proposal.score),
                    })
                  ),
                  {
                    trustee: new Address(NULL_ADDRESS),
                    score: parseInt(currencyGovernance.defaultProposalScore),
                  },
                ];

                // set votes
                monetaryVotes = currencyGovernance.votes.map(
                  (vote): Vote => ({
                    trustee: new Address(vote.trustee.id),
                    rankedProposals: vote.rankedProposals.map(
                      (proposal) => new Address(proposal)
                    ),
                  })
                );
              }

              // get trustees for this generation (use last generations block number to find cohort)
              const trusteeCohort = [
                ...cohortsResult.data.after,
                ...cohortsResult.data.before,
              ].find(
                (cohort) =>
                  parseInt(cohort.blockNumber) <
                  parseInt(generations[index].blockNumber)
              );

              return {
                id: generation.id,
                scores: monetaryScores,
                votes: monetaryVotes,
                proposals: monetaryProposals,
                trustees: trusteeCohort
                  ? trusteeCohort.trustees.map(
                      (trustee) => new Address(trustee.id)
                    )
                  : [],
                winningProposal: winningProposal! || DEFAULT_PROPOSAL,
              };
            }
          );

          setPastGenerationResults(pastGenerations);
        }
      } catch (err) {
        console.log(err);
      }
    };
    refreshPastGenerations();
    let pollTimer = setInterval(() => refreshPastGenerations(), 30_000);

    return () => {
      clearInterval(pollTimer);
    };
  }, [contractAddresses.currencyGovernance, apolloClient, limit]);

  return pastGenerationResults;
};

export default usePastGenerationResults;
