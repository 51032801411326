import './Header.css';

import { Button, PageHeader } from 'antd';

import { ReactComponent as Logo } from '../../assets/Darklogo.svg';

import StageDisplay from '../StageDisplay';
import { useNavigate } from 'react-router-dom';

// displays the Header

export default function Header({ admin, subTitle, extras, children }) {
  const navigate = useNavigate();

  return (
    <PageHeader
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '600px',
          }}
        >
          <Logo style={{ height: '42px', margin: 0 }} />
          <h2 className="brand">MONETARY CONTROLS</h2>
        </div>
      }
      subTitle={subTitle}
      extra={extras}
      footer={children}
    >
      {admin && <StageDisplay />}  
    </PageHeader>
  );
}
