import { gql } from '@apollo/client';

type SubgraphMonetaryCommit = {
  trustee: { id: string };
};

type SubgraphMonetaryVote = {
  trustee: { id: string };
  rankedProposals: string[];
};

type SubgraphCurrencyGovernance = {
  proposalEnds: string;
  votingEnds: string;
  revealEnds: string;
  defaultProposalMultiplier: string;
  defaultProposalScore: string;
  defaultProposalEnacted: boolean;
  commits: SubgraphMonetaryCommit[];
  votes: SubgraphMonetaryVote[];
};

type SubgraphMonetaryProposal = {
  trustee: { id: string };
  inflationMultiplier: string;
  randomInflationReward: string;
  numberOfRecipients: string;
  lockupDuration: string;
  lockupInterest: string;
  description: string;
  enacted: boolean;
  score: string;
};

type Generation = {
  id: string;
  blockNumber: string;
  currencyGovernance: SubgraphCurrencyGovernance | null;
  monetaryProposals: SubgraphMonetaryProposal[];
  nextGenerationStart: string | null;
};

export type PastGenerationsQueryResult = {
  generations: Generation[];
};

export const PAST_GENERATIONS = gql`
  query PastGenerations($limit: Int) {
    generations(orderBy: number, orderDirection: desc, first: $limit) {
      id
      blockNumber
      nextGenerationStart
      currencyGovernance {
        proposalEnds
        votingEnds
        revealEnds
        defaultProposalMultiplier
        defaultProposalScore
        defaultProposalEnacted
        commits {
          trustee {
            id
          }
        }
        votes {
          trustee {
            id
          }
          rankedProposals
        }
      }
      monetaryProposals {
        trustee {
          id
        }
        inflationMultiplier
        randomInflationReward
        numberOfRecipients
        lockupDuration
        lockupInterest
        description
        enacted
        score
      }
    }
  }
`;
