import { useApolloClient } from '@apollo/client';
import { BigNumber } from 'ethers';
import { createContext, ReactChild, useEffect, useState } from 'react';
import { ECO_TOKEN, EcoTokenQueryResult } from '../../queries';
import { Address, Erc20Interface } from '../../types';

const DEFAULT_INFLATION = BigNumber.from('1000000000000000000');

type Erc20ProviderProps = {
  children: ReactChild;
};

export const SubgraphErc20Context = createContext<Erc20Interface>({
  totalSupply: BigNumber.from(0),
  address: null,
});

export default function SubgraphErc20Provider({
  children,
}: Erc20ProviderProps) {
  const apolloClient = useApolloClient();

  const [totalSupply, setTotalSupply] = useState<BigNumber>(BigNumber.from(0));
  const [address, setAddress] = useState<Address | null>(null);

  const refreshErc20 = async () => {
    try {
      const result = await apolloClient.query<EcoTokenQueryResult>({
        query: ECO_TOKEN(),
      });

      const contractAddresses = result.data.contractAddresses;
      if (contractAddresses) {
        setAddress(new Address(contractAddresses.eco));
      }

      const token = result.data.token;
      if (token) {
        const currentInflationMultiplier = result.data.inflationMultipliers
          .length
          ? BigNumber.from(result.data.inflationMultipliers[0].value)
          : DEFAULT_INFLATION;

        setTotalSupply(
          BigNumber.from(token.totalSupply).div(currentInflationMultiplier)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    refreshErc20();
    let pollTimer = setInterval(() => refreshErc20(), 5_000);
    return () => {
      clearInterval(pollTimer);
    };
  }, []);

  return (
    <SubgraphErc20Context.Provider value={{ totalSupply, address }}>
      {children}
    </SubgraphErc20Context.Provider>
  );
}
