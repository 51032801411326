import { useState, useEffect } from 'react';

import CommitList from '../components/CommitList';
import Vote from '../components/Vote';
import { Button, List, Typography, Divider, Row, Col, Upload } from 'antd';

import { useContractAddresses } from '../providers/ContractAddressProvider';
import { useGovernance } from '../providers/GovernanceProvider';

import { toast } from 'react-toastify';
import { ethers } from 'ethers';

import { toastTxError } from '../helpers';

import currencyGovernanceABI from '../assets/abi/CurrencyGovernance.json';
import { GovernanceStage } from '../types';
import ProposalTable from '../components/ProposalTable';
import InfoBubble from '../components/InfoBubble';

/**
 * Reveal your vote after committing
 *
 * input seed generated by commit
 * sortable list of proposals
 *
 * keeps track of vote status as reveals come in
 *
 */

export default function Reveal({ signer }) {
  const contracts = useContractAddresses();
  const gov = useGovernance();

  const [seed, setSeed] = useState(null);
  const [hash, setHash] = useState(null);
  const [sortedList, setSortedList] = useState([]);

  const [alreadyRevealed, setAlreadyRevealed] = useState(false);
  const [address, setAddress] = useState(null);

  const uploadProps = {
    beforeUpload: (file) => {
      if (file.type !== 'text/plain') {
        toast.info(
          `${file.name} is not in a supported file format (should be .txt)`
        );
      } else {
        const readURL = new window.FileReader();

        readURL.readAsText(file);
        readURL.onload = () => {
          try {
            const commit = JSON.parse(readURL.result);
            //console.log(commit);

            if (!commit.seed || !commit.vote || !commit.hash) {
              toast.error(
                'Commit file uploaded does not have attributes needed to reveal vote, please check contents'
              );
            }

            setSeed(commit.seed);
            setHash(commit.hash);

            const myVote = Object.values(commit.vote);

            const newList = [];
            let index = -1;
            for (var i = 0; i < myVote.length; i++) {
              index = gov.proposals.findIndex((prpsl) =>
                prpsl.trustee.eq(myVote[i])
              );
              if (index >= 0) {
                newList.push(gov.proposals[index]);
              }
              index = -1;
            }
            // update vote list
            setSortedList(newList);

            toast.success('Commit file uploaded');
          } catch (err) {
            console.log(err);
            toast.error(
              'Could not parse JSON from upload file, please check contents'
            );
          }
        };
      }
      return false;
    },
    onDrop: () => {
      console.log('dropping image');
    },
  };

  async function revealVote(e) {
    if (e) {
      e.preventDefault();
    }

    if (!signer) {
      toast.error('Please Connect a wallet to reveal your vote');
      return;
    }

    if (sortedList.length < 1) {
      toast.error('There are no proposals to reveal for');
      return;
    }

    let revealTx;
    try {
      const myAddress = await signer.getAddress();

      // check if already revealed
      if (gov.votes.findIndex((vote) => vote.trustee.eq(myAddress)) >= 0) {
        toast.info('You have already revealed your vote');
        return;
      }

      if (!seed) {
        toast.error(
          'Please upload the commit file that was generated when you committed'
        );
        return;
      }

      const currencyGovernance = new ethers.Contract(
        contracts.currencyGovernance.toString(),
        currencyGovernanceABI.abi,
        signer
      );

      const vote = sortedList.map((p) => p.trustee.toString());

      const formattedVote = vote
        .map((address, index, array) => ({
          proposal: address.toLowerCase(),
          score: array.length - index,
        }))
        .sort((a, b) => a.proposal.localeCompare(b.proposal));

      // send transaction to chain
      revealTx = await currencyGovernance.reveal(seed, formattedVote, {
        gasLimit: 1_000_000,
      });

      const revealed = await revealTx.wait();

      if (!revealed.status) {
        console.log(revealed);
        throw new Error('Error while revealing vote');
      }

      setSeed(null);
      setHash(null);
      toast.success('Successful Reveal');

      setAlreadyRevealed(true);
    } catch (err) {
      console.log(err);
      toastTxError({
        txHash: revealTx?.hash,
        message: 'Failed to reveal your vote',
        err,
      });
    }
  }

  useEffect(() => {
    if (gov.proposals) {
      // check for existing proposals and maintain current order
      const newProposals = gov.proposals.filter(
        (proposal) =>
          !sortedList.some((sortedProposal) =>
            sortedProposal.trustee.eq(proposal.trustee)
          )
      );
      setSortedList([...sortedList, ...newProposals]);
    }
  }, [gov.proposals]);

  useEffect(() => {
    async function checkRevealed() {
      try {
        const myAddress = await signer.getAddress();
        setAddress(myAddress);
        let found = false;
        for (var i = 0; i < gov.votes.length; i++) {
          if (gov.votes[i].trustee.eq(myAddress)) {
            found = true;
          }
        }
        setAlreadyRevealed(found);
      } catch (err) {
        console.log(err);
      }
    }

    if (signer) {
      checkRevealed();
    } else {
      setAlreadyRevealed(false);
      setAddress(null);
    }
  }, [signer, gov.votes]);

  return (
    <div style={{ margin: 48 }}>
      <div>
        {!alreadyRevealed ? (
          <form onSubmit={revealVote}>
            <Row justify="space-around" align="top" gutter={[24, 32]}>
              <Col
                md={{ span: 20, order: 1 }}
                lg={{ span: 20, order: 1 }}
                style={{ textAlign: 'left' }}
              >
                <Typography.Title level={3}>
                  Reveal Vote
                  <InfoBubble text="Upload the commit file created when you committed that contains your unique seed, encrypted vote, and copy of your ranked proposals." />
                </Typography.Title>
                <Typography.Paragraph>
                  To reveal your vote,{' '}
                  <Upload {...uploadProps} fileList={[]}>
                    <Typography.Link>Upload your Commit file</Typography.Link>
                  </Upload>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Encrypted vote: {hash}
                </Typography.Paragraph>
              </Col>
              <Col md={{ span: 4, order: 2 }} lg={{ span: 4, order: 2 }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={revealVote}
                  disabled={gov.stage !== GovernanceStage.Reveal}
                >
                  Reveal Vote
                </Button>
              </Col>
            </Row>
            <br />
            <Row justify="space-around" align="middle" gutter={[24, 32]}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <ProposalTable list={sortedList} ranked signer={signer} />
              </Col>
            </Row>
            <Divider />
          </form>
        ) : (
          <Row justify="space-around" align="top" gutter={[24, 32]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Typography.Title level={4} style={{ textAlign: 'left' }}>
                Your Revealed Vote
              </Typography.Title>
              <List
                bordered
                size="large"
                dataSource={gov.votes.filter((vote) =>
                  vote.trustee.eq(address)
                )}
                renderItem={(vote) => (
                  <Vote
                    {...vote}
                    proposals={gov.proposals}
                    key={'vote-' + vote.trustee.toString()}
                  />
                )}
              />
            </Col>
          </Row>
        )}
        <br />
        <Row justify="space-around" align="top" gutter={[24, 32]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={4} style={{ textAlign: 'left' }}>
              Trustees with Unrevealed Votes
            </Typography.Title>
            <CommitList />
          </Col>
        </Row>
      </div>
    </div>
  );
}
