import { ReactChild, useContext } from 'react';
import InfuraErc20Provider, { InfuraErc20Context } from './InfuraErc20Provider';
import SubgraphErc20Provider, {
  SubgraphErc20Context,
} from './SubgraphErc20Provider';

let globalAny: any = global;

type Erc20ProviderProps = {
  children: ReactChild;
};

export default function Erc20Provider({ children }: Erc20ProviderProps) {
  if (globalAny.env.useSubgraph) {
    return <SubgraphErc20Provider>{children}</SubgraphErc20Provider>;
  } else {
    return <InfuraErc20Provider>{children}</InfuraErc20Provider>;
  }
}

export const useErc20 = () =>
  useContext(
    globalAny.env.useSubgraph ? SubgraphErc20Context : InfuraErc20Context
  );
