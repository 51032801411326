import { gql } from '@apollo/client';

type SubgraphContractAddresses = {
  policy: string;
  timedPolicies: string;
  currencyTimer: string;
  currencyGovernance: string;
  eco: string;
  ecox: string;
  trustedNodes: string;
};

export type ContractAddressQueryResult = {
  contractAddresses: SubgraphContractAddresses | null;
};

export const CONTRACT_ADDRESSES = () => gql`
  query contractAddresses {
    contractAddresses(id: "0") {
      policy
      timedPolicies
      currencyTimer
      currencyGovernance
      eco
      ecox
      trustedNodes
    }
  }
`;
