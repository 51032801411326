import { ReactChild, useContext } from 'react';

import InfuraGovernanceProvider, {
  InfuraGovernanceContext,
} from './InfuraGovernanceProvider';
import SubgraphGovernanceProvider, {
  SubgraphGovernanceContext,
} from './SubgraphGovernanceProvider';

let globalAny: any = global;

type GovernanceProviderProps = {
  children: ReactChild;
};

export default function GovernanceProvider({
  children,
}: GovernanceProviderProps) {
  if (globalAny.env.useSubgraph) {
    return <SubgraphGovernanceProvider>{children}</SubgraphGovernanceProvider>;
  } else {
    return <InfuraGovernanceProvider>{children}</InfuraGovernanceProvider>;
  }
}

export const useGovernance = () =>
  useContext(
    globalAny.env.useSubgraph
      ? SubgraphGovernanceContext
      : InfuraGovernanceContext
  );
