import { Row, Col, Pagination, Typography, List } from 'antd';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { usePastGenerationResults } from '../hooks';

import { GovernanceProposal, Vote as VoteType } from '../types';

import Vote from '../components/Vote';

const pageSize = 5;

function Wallet({ signer }: { signer: ethers.Signer }) {
  const pastGenerationResults = usePastGenerationResults();

  const [currentPage, setCurrentPage] = useState(1);
  const [myVotes, setMyVotes] = useState<
    { generation: string; proposals: GovernanceProposal[]; vote: VoteType }[]
  >([]);

  const pageChanged = (number: number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    const getMyVotes = async () => {
      if (signer) {
        const myAddress = await signer.getAddress();

        setMyVotes(
          pastGenerationResults
            .filter((generationResult) =>
              generationResult.votes.some((vote) => vote.trustee.eq(myAddress))
            )
            .map((generation) => ({
              generation: generation.id,
              proposals: generation.proposals,
              vote: generation.votes.find((vote) =>
                vote.trustee.eq(myAddress)
              )!,
            }))
        );
      }
    };
    getMyVotes();
  }, [pastGenerationResults, signer]);

  return (
    <div style={{ margin: 32 }}>
      <Row justify="space-around" align="top" gutter={[24, 32]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={8}>
          <Typography.Title level={2}>My Past Votes</Typography.Title>
          {myVotes
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((generation) => (
              <div>
                <Typography.Title level={5} style={{ textAlign: 'left' }}>
                  Generation {generation.generation}
                </Typography.Title>
                <List
                  bordered
                  //itemLayout="vertical"
                  size="large"
                  dataSource={[generation.vote]}
                  renderItem={(vote) => (
                    <Vote
                      trustee={generation.vote.trustee.toString()}
                      proposals={generation.proposals}
                      rankedProposals={generation.vote.rankedProposals}
                    />
                  )}
                />
                <br />
              </div>
            ))}
          <Pagination
            total={myVotes.length}
            pageSize={pageSize}
            onChange={pageChanged}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Wallet;
