import { gql } from '@apollo/client';

type SubgraphTrustee = {
  id: string;
};

type SubgraphTrusteeCohort = {
  number: string;
  trustees: SubgraphTrustee[];
};

export type CurrentTrusteesQueryResult = {
  trusteeCohorts: SubgraphTrusteeCohort[];
};

export const CURRENT_TRUSTEES = () => gql`
  query currentTrustees {
    trusteeCohorts(orderBy: number, orderDirection: desc, limit: 1) {
      number
      trustees {
        id
      }
    }
  }
`;
