import { useApolloClient } from '@apollo/client';
import { createContext, ReactChild, useEffect, useState } from 'react';
import { CURRENT_TRUSTEES, CurrentTrusteesQueryResult } from '../../queries';
import { Address } from '../../types';

type TrusteeProviderProps = {
  children: ReactChild;
};

export const SubgraphTrusteeContext = createContext<Address[]>([]);

export default function SubgraphTrusteeProvider({
  children,
}: TrusteeProviderProps) {
  const apolloClient = useApolloClient();

  const [trustees, setTrustees] = useState<Address[]>([]);

  const refreshTrustees = async () => {
    try {
      const result = await apolloClient.query<CurrentTrusteesQueryResult>({
        query: CURRENT_TRUSTEES(),
      });
      if (result.data.trusteeCohorts.length) {
        const trusteeCohort = result.data.trusteeCohorts[0];

        setTrustees(
          trusteeCohort.trustees.map((trustee) => new Address(trustee.id))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshTrustees();
    let pollTimer = setInterval(() => refreshTrustees(), 5_000);
    return () => {
      clearInterval(pollTimer);
    };
  }, []);

  return (
    <SubgraphTrusteeContext.Provider value={trustees}>
      {children}
    </SubgraphTrusteeContext.Provider>
  );
}
