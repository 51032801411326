import { Card } from "antd";
import { Wallet } from "ethers";
import { useRef } from "react";
import image from "../assets/media/spiral-2.png";

function AccessGate({signer}: {signer: Wallet}) {

    const boxRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={boxRef} style={{display: 'grid', gridTemplateColumns: '50% 50%', height: `calc(100vh - ${boxRef.current?.offsetTop || 0}px)`, backgroundColor: 'rgb(0, 11, 139)'}} >
            <div style={{placeSelf: 'center'}} > 
                <img src={image} alt="..." width="110%" />
            </div>
            <div style={{placeSelf: 'center', maxWidth: '450px'}}>
                <Card
                    title="Welcome to Eco Monetary Controls"
                >
                    {signer ? "Your connected wallet is not a trustee and cannot vote on monetary governance" : "No wallet is connected"}, please connect a trustee wallet for access.
                </Card>
            </div>
        </div>
    )
}

export default AccessGate;