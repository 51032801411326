import { useCallback, useEffect, useState } from 'react';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';

import { Alert, Button, Divider } from 'antd';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';

import { ethers } from 'ethers';
import { INFURA_ID } from './NETWORKS';
import { NETWORK } from './constants';
import { changeNetwork } from './helpers';
import { useUserSigner } from './hooks';

import Header from './components/Header';
import Account from './components/Account';
import Static from './views/Static';
import Propose from './views/Propose';
import Commit from './views/Commit';
import Reveal from './views/Reveal';
import Wallet from './views/Wallet';

import GovProvider from './providers/GovernanceProvider';
import { useTrustees } from './providers/TrusteeProvider';
import Execute from './views/Execute';
import { WalletOutlined } from '@ant-design/icons';
import AccessGate from './components/AccessGate';

const NETWORKCHECK = true; // show msg if user is not on default network

const mainnetInfura = navigator.onLine
  ? new ethers.providers.JsonRpcProvider(
      'https://mainnet.infura.io/v3/' + INFURA_ID
    )
  : null;
console.log('infura:', mainnetInfura);

const localProvider = new ethers.providers.JsonRpcProvider(
  global.env.desiredNetwork.rpcUrl
);
console.log('local provider: ', localProvider);

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
        rpc: {
          56: 'https://bsc-dataseed.binance.org/',
          100: 'https://dai.poa.network/',
          137: 'https://rpc-mainnet.maticvigil.com/',
          250: 'https://rpc.ftm.tools',
          43114: 'https://api.avax.network/ext/bc/C/rpc/',
          1666600000: 'https://api.harmony.one',
        },
      },
    },
  },
});

async function logoutOfWeb3Modal() {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
}

function App() {
  const mainnetProvider = mainnetInfura;

  const navigate = useNavigate();
  const location = useLocation();

  const [injectedProvider, setInjectedProvider] = useState(null);
  const [address, setAddress] = useState(null);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const trustees = useTrustees();

  const userSigner = useUserSigner(injectedProvider, localProvider);

  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    console.log(address, trustees);
    function checkAdmin() {
      if (
        !global.env.onlyTrustees ||
        (trustees.find((trustee) => trustee.eq(address)))
      ) {
        setAdmin(true);
      } else if (trustees.length) {
        setAdmin(false);
        if (location.pathname !== "/") {
            navigate("/");
        }
      }
    }
    checkAdmin();
  }, [address, trustees]);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // PAGE LOAD FUNCTIONS
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [networkDisplay, setNetworkDisplay] = useState(null);

  // Check if user connected is on the wrong network
  async function resetNetworkDisplay() {
    const localChainId =
      localProvider && localProvider._network && localProvider._network.chainId;
    const currNetwork =
      userSigner && userSigner.provider
        ? await userSigner.provider.getNetwork()
        : null;
    const selectedChainId = currNetwork ? currNetwork.chainId : null;

    if (
      NETWORKCHECK &&
      localChainId &&
      selectedChainId &&
      localChainId !== selectedChainId
    ) {
      console.log('selected chain: ', selectedChainId);
      console.log('local chain: ', localChainId);

      const networkSelected = NETWORK(selectedChainId);
      const networkLocal = NETWORK(localChainId);
      if (selectedChainId === 1337 && localChainId === 31337) {
        setNetworkDisplay(
          <div
            style={{
              zIndex: 2,
              position: 'absolute',
              right: 0,
              top: 60,
              padding: 16,
            }}
          >
            <Alert
              message="⚠️ Wrong Network ID"
              description={
                <div>
                  You have <b>chain id 1337</b> for localhost and you need to
                  change it to <b>31337</b> to work with HardHat.
                  <div>
                    (MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt;
                    31337)
                  </div>
                </div>
              }
              type="error"
              closable={false}
            />
          </div>
        );
      } else {
        setNetworkDisplay(
          <div
            style={{
              zIndex: 2,
              position: 'absolute',
              right: 0,
              top: 60,
              padding: 16,
            }}
          >
            <Alert
              message="⚠️ Wrong Network"
              description={
                <div>
                  You are on{' '}
                  <b>
                    {networkSelected
                      ? networkSelected.name
                      : 'an unsupported network'}
                  </b>
                  , please
                  <Button
                    style={{ padding: '4px' }}
                    type="link"
                    onClick={() => changeNetwork(networkLocal)}
                  >
                    <b>Switch to {networkLocal && networkLocal.name}</b>
                  </Button>
                </div>
              }
              type="error"
              closable={false}
            />
          </div>
        );
      }
    } else {
      setNetworkDisplay(null);
    }
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on('chainChanged', (chainId) => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on('accountsChanged', () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on('disconnect', (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
      resetNetworkDisplay();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    resetNetworkDisplay();
  }, [injectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  // Account component
  function MyAccount() {
    return (
      <Account
        key="extra-3"
        address={address}
        mainnetProvider={mainnetProvider}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        blockExplorer={global.env.desiredNetwork.blockExplorer}
      />
    );
  }

  return (
    <GovProvider>
      <div className="App">
        <div
          style={{
            height: windowHeight,
            minHeight: '500px',
            overflowY: 'auto',
          }}
        >
          {networkDisplay}
          <Header
            signer={userSigner}
            admin={admin}
            extras={[
              userSigner && admin ? (
                <Link to="/wallet">
                  <Button icon={<WalletOutlined />} size="large" type="primary">
                    My History
                  </Button>
                </Link>
              ) : null,
              <MyAccount />,
            ]}
          />
          <Divider style={{margin: 0, padding: 0}} />
          {!admin ? (
            <AccessGate signer={userSigner} />
          ) : (
            <div>
                <Routes>
                {/* Default page */}
                <Route
                    exact
                    path="/"
                    element={admin ? <Propose signer={userSigner} /> : null}
                />
                <Route
                    path="/vote"
                    element={admin ? <Commit signer={userSigner} /> : null}
                />
                <Route
                    path="/reveal"
                    element={admin ? <Reveal signer={userSigner} /> : null}
                />
                <Route
                    path="/execute"
                    element={admin ? <Execute signer={userSigner} /> : null}
                />
                <Route
                    path="/wallet"
                    element={admin ? <Wallet signer={userSigner} /> : null}
                />
                </Routes>
          </div>
          )}
        </div>
        <ToastContainer />
      </div>
    </GovProvider>
  );
}

export default App;
