import { ethers, BigNumber } from 'ethers';
import { createContext, useMemo, useState, useEffect, ReactChild } from 'react';
import { isNullAddress } from '../../helpers';

import { useContractAddresses } from '../ContractAddressProvider';

import { ethProvider } from '../../constants';

import ecoABI from '../../assets/abi/ECO.json';

import { Erc20Interface } from '../../types';

export const InfuraErc20Context = createContext<Erc20Interface>({
  totalSupply: BigNumber.from(0),
  address: null,
});

/**
 * Erc20Provider
 *
 * gets total supply of the erc20 and listens for updates
 */

type Erc20ProviderProps = {
  children: ReactChild;
};

export default function InfuraErc20Provider({ children }: Erc20ProviderProps) {
  const contracts = useContractAddresses();

  const [totalSupply, setTotalSupply] = useState<BigNumber>(BigNumber.from(0));

  const eco = useMemo(() => {
    let result = null;
    if (contracts.eco) {
      result = new ethers.Contract(
        contracts.eco.toString(),
        ecoABI.abi,
        ethProvider()
      );
    }
    return result;
  }, [contracts.eco]);

  async function getTotalSupply() {
    if (eco) {
      try {
        const totSupply = await eco.totalSupply();

        setTotalSupply(totSupply);
      } catch (err) {
        console.log(err);
      }
    }
  }

  // on app load, subscribe to erc20 total supply and listen for transfers
  useEffect(() => {
    getTotalSupply();

    if (eco) {
      eco.on('Transfer', (from, to, amount) => {
        // check if mint or burn
        if (isNullAddress(from) || isNullAddress(to)) {
          getTotalSupply();
        }
      });

      eco.on('NewInflationMultiplier', () => {
        getTotalSupply();
      });
    }

    return () => {
      if (eco) {
        eco.removeAllListeners();
      }
    };
  }, [eco]);

  return (
    <InfuraErc20Context.Provider
      value={{ totalSupply, address: contracts.eco }}
    >
      {children}
    </InfuraErc20Context.Provider>
  );
}
