import { createContext, ReactChild, useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import { useApolloClient } from '@apollo/client';
import { PastGenerationsQueryResult, PAST_GENERATIONS } from '../../queries';
import {
  GovernanceInterface,
  GovernanceStage,
  GovernanceProposal,
  Commit,
  Vote,
  Score,
  Address,
} from '../../types';

import { NULL_ADDRESS, currTime } from '../../helpers';

const DEFAULT_PROPOSAL: GovernanceProposal = {
  trustee: new Address(NULL_ADDRESS),
  numberOfRecipients: BigNumber.from(0),
  randomInflationReward: BigNumber.from(0),
  lockupDuration: BigNumber.from(0),
  lockupInterest: BigNumber.from(0),
  inflationMultiplier: BigNumber.from('1000000000000000000'),
  description: 'The default proposal makes no changes',
};

export const SubgraphGovernanceContext = createContext<GovernanceInterface>({
  proposals: [],
  stage: GovernanceStage.Propose,
  stageEnds: null,
  commits: [],
  votes: [],
  scores: [],
  winner: null,
  nextGenerationStartsAt: null,
});

type GovernanceProviderProps = {
  children: ReactChild;
};

function SubgraphGovernanceProvider({ children }: GovernanceProviderProps) {
  const apolloClient = useApolloClient();

  const [stage, setStage] = useState<GovernanceStage>(GovernanceStage.Propose);
  const [stageEnds, setStageEnds] = useState<number | null>(null);

  const [proposals, setProposals] = useState<GovernanceProposal[]>([]);
  const [votes, setVotes] = useState<Vote[]>([]);
  const [scores, setScores] = useState<Score[]>([]);
  const [commits, setCommits] = useState<Commit[]>([]);
  const [winner, setWinner] = useState<GovernanceProposal | null>(null);

  const [nextGenerationStartsAt, setNextGenerationStartsAt] = useState<
    number | null
  >(null);

  const refreshGovernance = async () => {
    try {
      const result = await apolloClient.query<PastGenerationsQueryResult>({
        query: PAST_GENERATIONS,
        variables: { limit: 1 },
        fetchPolicy: 'no-cache',
      });

      if (result.data.generations[0].nextGenerationStart) {
        setNextGenerationStartsAt(
          parseInt(result.data.generations[0].nextGenerationStart)
        );
      }

      let subgraphMonetaryProposals =
        result.data.generations[0].monetaryProposals;

      // set proposals

      let monetaryProposals = [
        DEFAULT_PROPOSAL,
        ...subgraphMonetaryProposals.map(
          (proposal): GovernanceProposal => ({
            trustee: new Address(proposal.trustee.id),
            inflationMultiplier: BigNumber.from(proposal.inflationMultiplier),
            randomInflationReward: BigNumber.from(
              proposal.randomInflationReward
            ),
            numberOfRecipients: BigNumber.from(proposal.numberOfRecipients),
            lockupDuration: BigNumber.from(proposal.lockupDuration),
            lockupInterest: BigNumber.from(proposal.lockupInterest),
            description: proposal.description,
          })
        ),
      ];

      setProposals(monetaryProposals);

      if (result.data.generations[0].currencyGovernance) {
        let currencyGovernance = result.data.generations[0].currencyGovernance;

        // set scores
        setScores([
          ...subgraphMonetaryProposals.map(
            (proposal): Score => ({
              trustee: new Address(proposal.trustee.id),
              score: parseInt(proposal.score),
            })
          ),
          {
            trustee: new Address(NULL_ADDRESS),
            score: parseInt(currencyGovernance.defaultProposalScore),
          },
        ]);

        // set stage and stage ends
        if (parseInt(currencyGovernance.revealEnds) < currTime()) {
          // compute/finished
          setStageEnds(null);
          // check if default or reg proposal was enacted
          if (
            currencyGovernance.defaultProposalEnacted ||
            subgraphMonetaryProposals.find((proposal) => proposal.enacted)
          ) {
            setStage(GovernanceStage.Finished);

            // set winner
            if (currencyGovernance.defaultProposalEnacted) {
              setWinner(DEFAULT_PROPOSAL);
            } else {
              let winningProposal = subgraphMonetaryProposals.find(
                (proposal) => proposal.enacted
              )!;
              setWinner({
                trustee: new Address(winningProposal.trustee.id),
                inflationMultiplier: BigNumber.from(
                  winningProposal.inflationMultiplier
                ),
                randomInflationReward: BigNumber.from(
                  winningProposal.randomInflationReward
                ),
                numberOfRecipients: BigNumber.from(
                  winningProposal.numberOfRecipients
                ),
                lockupDuration: BigNumber.from(winningProposal.lockupDuration),
                lockupInterest: BigNumber.from(winningProposal.lockupInterest),
                description: winningProposal.description,
              });
            }
          } else {
            setStage(GovernanceStage.Compute);
          }
        } else if (parseInt(currencyGovernance.votingEnds) < currTime()) {
          // reveal
          setStage(GovernanceStage.Reveal);
          setStageEnds(parseInt(currencyGovernance.revealEnds));
        } else if (parseInt(currencyGovernance.proposalEnds) < currTime()) {
          // commit
          setStage(GovernanceStage.Commit);
          setStageEnds(parseInt(currencyGovernance.votingEnds));
        } else {
          // propose
          setStage(GovernanceStage.Propose);
          setStageEnds(parseInt(currencyGovernance.proposalEnds));
        }

        // set commits filtering out any that are already revealed
        setCommits(
          currencyGovernance.commits
            .filter(
              (commit) =>
                !currencyGovernance.votes.some(
                  (vote) => vote.trustee.id === commit.trustee.id
                )
            )
            .map(
              (commit): Commit => ({
                trustee: new Address(commit.trustee.id),
              })
            )
        );

        // set votes
        setVotes(
          currencyGovernance.votes.map(
            (vote): Vote => ({
              trustee: new Address(vote.trustee.id),
              rankedProposals: vote.rankedProposals.map(
                (proposal) => new Address(proposal)
              ),
            })
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    refreshGovernance();
    let pollTimer = setInterval(() => refreshGovernance(), 5_000);

    return () => {
      clearInterval(pollTimer);
    };
  }, []);

  useEffect(() => {
    if (stage > GovernanceStage.Commit) {
      window.sessionStorage.removeItem('commit');
    }
  }, [stage]);

  return (
    <SubgraphGovernanceContext.Provider
      value={{
        stage,
        stageEnds,
        proposals,
        votes,
        scores,
        commits,
        winner,
        nextGenerationStartsAt,
      }}
    >
      {children}
    </SubgraphGovernanceContext.Provider>
  );
}

export default SubgraphGovernanceProvider;
