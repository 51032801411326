import { Table, Progress, Tooltip } from 'antd';
import update from 'immutability-helper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  isNullAddress,
  proposalToDisplay,
  shortAddress,
  limitDecimals,
} from '../helpers';
import { useErc20 } from '../providers/Erc20Provider';

import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg';

const type = 'DraggableBodyRow';

const DraggableProposalRow = ({
  index,
  moveRow,
  setList,
  className,
  style,
  address,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let trusteeElement = restProps.children.length
    ? restProps.children.find((child) => child.key === 'trustee')
    : null;

  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: setList ? 'move' : 'default',
        backgroundColor:
          address &&
          trusteeElement &&
          trusteeElement.props.record.trustee.eq(address)
            ? '#FFDD50'
            : 'inherit',
        ...style,
      }}
      {...restProps}
    />
  );
};

const ProposalRow = ({ index, className, style, address, ...restProps }) => {
  let trusteeElement = restProps.children.length
    ? restProps.children.find((child) => child.key === 'trustee')
    : null;

  return (
    <tr
      className={`${className}`}
      style={{
        backgroundColor:
          address &&
          trusteeElement &&
          trusteeElement.props.record.trustee.eq(address)
            ? '#FFDD50'
            : 'inherit',
        ...style,
      }}
      {...restProps}
    />
  );
};

const colStyle = {
  fontSize: 12,
};

const columns = [
  {
    title: <div style={colStyle}>Rank</div>,
    dataIndex: 'rank',
    key: 'rank',
    align: 'center',
    render: (rank) => rank,
  },
  {
    title: <div style={colStyle}>Proposer</div>,
    dataIndex: 'trustee',
    key: 'trustee',
    align: 'center',
    render: (proposer) =>
      isNullAddress(proposer) ? (
        'Default Proposal'
      ) : (
        <Tooltip
          overlayStyle={{ maxWidth: 400 }}
          title={proposer.toString()}
        >{`...${proposer.toString().slice(38)}`}</Tooltip>
      ),
  },
  {
    title: <div style={colStyle}>Description</div>,
    dataIndex: 'description',
    key: 'desc',
    align: 'center',
    render: (description) =>
      description ? (
        <Tooltip overlayStyle={{ maxWidth: 300 }} title={description}>
          <ChatIcon width={20} />
        </Tooltip>
      ) : null,
  },
  {
    title: <div style={colStyle}>Linear Supply Change</div>,
    dataIndex: 'inflationMultiplier',
    key: 'inflationMultiplier',
    align: 'center',
    render: (inflationMultiplier) =>
      `${inflationMultiplier >= 0 ? '+' : '-'}${limitDecimals(
        Math.abs(inflationMultiplier),
        1
      )}% `,
  },
  {
    title: <div style={colStyle}>Random Inflation Amount</div>,
    dataIndex: 'randomInflationReward',
    key: 'randomInflationReward',
    align: 'center',
    render: (randomInflationReward) =>
      `${limitDecimals(randomInflationReward)}%`,
  },
  {
    title: <div style={colStyle}>Number of Winning Events</div>,
    dataIndex: 'numberOfRecipients',
    key: 'numberOfRecipients',
    align: 'center',
    render: (numberOfRecipients) =>
      limitDecimals(numberOfRecipients).toLocaleString(),
  },
  {
    title: <div style={colStyle}>Lockup Interest (APR)</div>,
    dataIndex: 'lockupInterest',
    key: 'lockupInterest',
    align: 'center',
    render: (lockupInterest) => `${limitDecimals(lockupInterest)}%`,
  },
  {
    title: <div style={colStyle}>Lockup Duration (days)</div>,
    dataIndex: 'lockupDuration',
    key: 'lockupDuration',
    align: 'center',
    render: (lockupDuration) => `${limitDecimals(lockupDuration, 1)}`,
  },
  {
    title: <div style={colStyle}>Score</div>,
    dataIndex: 'score',
    key: 'score',
    align: 'center',
    render: (score) => (
      <Progress
        type="dashboard"
        percent={score.percent * 100}
        success={{ percent: 0 }}
        format={() => score.value}
        width={50}
      />
    ),
  },
];

const ProposalTable = ({ list, setList, ranked, scores, maxScore, signer }) => {
  const erc20 = useErc20();

  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      if (signer) {
        const myAddress = await signer.getAddress();
        setAddress(myAddress);
      }
    };
    fetchAddress();
  }, [signer]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = list[dragIndex];
      if (setList) {
        setList(
          update(list, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
      }
    },
    [list]
  );

  const components = {
    body: {
      row: ranked ? DraggableProposalRow : ProposalRow,
    },
  };

  let proposals = list.map((proposal) => {
    let displayProposal = proposalToDisplay(proposal, erc20.totalSupply);
    if (scores) {
      let score = scores.find((score) => score.trustee.eq(proposal.trustee));
      displayProposal.score = {
        percent: score ? score.score / maxScore : 0,
        value: score ? score.score : 0,
      };
      // score stored as percent
    }
    return displayProposal;
  });

  // hide/show rank and score columns
  let useColumns = columns;

  if (!scores) {
    useColumns = useColumns.slice(0, useColumns.length - 1);
  } else {
    proposals = proposals.sort((a, b) => b.score.value - a.score.value);
  }

  if (!ranked) {
    useColumns = useColumns.slice(1);
  } else {
    proposals = proposals.map((proposal, index) => ({
      ...proposal,
      rank: index + 1,
    }));
  }

  const TableElement = (
    <Table
      id="proposal-table"
      size="small"
      pagination={false}
      bordered
      columns={useColumns}
      dataSource={proposals}
      components={components}
      rowKey="trustee"
      onRow={(_, index) => ({
        index,
        moveRow,
        setList,
        address,
      })}
    />
  );

  return ranked ? (
    <DndProvider backend={HTML5Backend}>{TableElement}</DndProvider>
  ) : (
    TableElement
  );
};

export default ProposalTable;
