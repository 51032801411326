export class Address {
  address: string;

  constructor(address: string) {
    this.address = address;
  }

  eq = (address: string | Address | null) => {
    if (!address) {
      return null;
    } else if (typeof address === 'string') {
      return this.address.toLowerCase() === address.toLowerCase();
    } else {
      return this.address.toLowerCase() === address.address.toLowerCase();
    }
  };

  toString = () => {
    return this.address;
  };
}
