import { ethers } from 'ethers';

import NETWORKS from './NETWORKS';
import policyABI from './assets/abi/Policy.json';

// hashes for different contracts (used for finding current contract instance through Policy.policyFor(<ID>)).
export const ID_ECO =
  '0xe0391e627a5766ef56109c7c98e0542c6e96a116720d7c626119be5b67e1813d';
export const ID_FAUCET =
  '0x93824b3fb91a9a455e79c6bb5ad7a2acaedbf7fea80464761d7d892aa7853d5e';
export const ID_TIMED_POLICIES =
  '0xae30bfb87dec2bd0c16be9790f95842d84f58dc70b0a8f6ed22e9556176a7b19';
export const ID_CURRENCY_GOVERNANCE =
  '0xe4ee44a5d338a8b2452cc9552ec014656668eaacb55683101b7e1c2b167e5225';
export const ID_TRUSTED_NODES =
  '0x0e3d3f2b74f96e5fd24f23acf8b4b352d4e1d0d0ed45271f4e44aa64f98b2284';
export const ID_CURRENCY_TIMER =
  '0xe01e721169f17f30d0c130781195719ceba11f26f44578668ffd8462c7c1ebe9';

const provider = new ethers.providers.JsonRpcProvider(
  global.env.desiredNetwork.rpcUrl
);

export function ethProvider() {
  return provider;
}

// Policy.sol contract
export const POLICY = new ethers.Contract(
  global.env.policyAddress,
  policyABI.abi,
  ethProvider()
);

export const NETWORK = (chainId) => {
  // could get by name or by chainId
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId || NETWORKS[n].name === chainId) {
      return NETWORKS[n];
    }
  }
};

export const PROVIDER = (chainId) => {
  // could get by name or by chainId
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId || NETWORKS[n].name === chainId) {
      return new ethers.providers.JsonRpcProvider(NETWORKS[n].rpcUrl);
    }
  }
};
