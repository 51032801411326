import { BigNumber, ethers } from 'ethers';
import { ReactChild, createContext, useState, useEffect, useMemo } from 'react';
import { ethProvider } from '../../constants';
import { isNullAddress } from '../../helpers';
import { useContractAddresses } from '../ContractAddressProvider';

import trustedNodesABI from '../../assets/abi/TrustedNodes.json';
import { Address } from '../../types';

/**
 * TrusteeProvider
 *
 * Maintains the list of trustees
 */

export const InfuraTrusteeContext = createContext<Address[]>([]);

type TrusteeProviderProps = {
  children: ReactChild;
};

export default function InfuraTrusteeProvider({
  children,
}: TrusteeProviderProps) {
  const contracts = useContractAddresses();

  const [trustees, setTrustees] = useState<Address[]>([]);
  const [cohort, setCohort] = useState(BigNumber.from(0));

  // trusted nodes contract
  const trustedNodes = useMemo(() => {
    let result = null;
    if (contracts.trustedNodes) {
      result = new ethers.Contract(
        contracts.trustedNodes.toString(),
        trustedNodesABI.abi,
        ethProvider()
      );
    }
    return result;
  }, [contracts.trustedNodes]);

  // listen for trustee updates when trustedNodes updates,
  // or when currencyGovernance refreshes (at the beginning of a new cycle, when a new cohort has probably been set)
  useEffect(() => {
    async function getTrustees() {
      if (!trustedNodes) {
        return;
      }
      try {
        // get current cohort
        const cohort = await trustedNodes.cohort();
        const count = await trustedNodes.numTrustees();

        setCohort(cohort);
        // local var
        let trstees: Address[] = [];

        let trstee;
        // get trustees 1 by 1
        for (var i = 0; i < count; i++) {
          trstee = await trustedNodes.getTrustedNodeFromCohort(cohort, i);

          // check for zero just in case
          if (!isNullAddress(trstee)) {
            trstees.push(new Address(trstee));
          }
        }
        console.log('trustees:', trstees);

        setTrustees(trstees);
      } catch (err) {
        console.log(err);
      }
    }

    if (trustedNodes) {
      getTrustees();

      // listen for trustee updates
      trustedNodes.on(
        'TrustedNodeAddition',
        (address: string, _cohort: BigNumber) => {
          // check for new cohort
          if (cohort.lt(_cohort)) {
            setCohort(_cohort);
            setTrustees([new Address(address)]);
          } else {
            // append new node
            setTrustees((trstees) => [...trstees, new Address(address)]);
          }
        }
      );
      trustedNodes.on(
        'TrustedNodeRemoval',
        (address: string, _cohort: BigNumber) => {
          // remove node
          if (cohort.eq(_cohort)) {
            setTrustees((trstees) =>
              trstees.filter((trstee) => !trstee.eq(address))
            );
          }
        }
      );
    }

    return () => {
      if (trustedNodes) {
        trustedNodes.removeAllListeners();
      }
    };
  }, [trustedNodes, contracts.currencyGovernance]);

  return (
    <InfuraTrusteeContext.Provider value={trustees}>
      {children}
    </InfuraTrusteeContext.Provider>
  );
}
