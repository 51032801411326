import { ReactChild, useContext } from 'react';

import SubgraphContractAddressProvider, {
  SubgraphContractAddressContext,
} from './SubgraphContractAddressProvider';
import InfuraContractAddressProvider, {
  InfuraContractAddressContext,
} from './InfuraContractAddressProvider';

import { ContractAddressesInterface } from '../../types';

let globalAny: any = global;

type ContractAddressProviderProps = {
  children: ReactChild;
};

export default function ContractAddressProvider({
  children,
}: ContractAddressProviderProps) {
  if (globalAny.env.useSubgraph) {
    return (
      <SubgraphContractAddressProvider>
        {children}
      </SubgraphContractAddressProvider>
    );
  } else {
    return (
      <InfuraContractAddressProvider>{children}</InfuraContractAddressProvider>
    );
  }
}

export const useContractAddresses = () =>
  useContext<ContractAddressesInterface>(
    globalAny.env.useSubgraph
      ? SubgraphContractAddressContext
      : InfuraContractAddressContext
  );
