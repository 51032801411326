// environment variables can be set here
import NETWORKS from './NETWORKS';

if (process.env.NODE_ENV === 'production') {
  global.env = {
    debug: false,
    onlyTrustees: !!parseInt(process.env.REACT_APP_ONLY_TRUSTEES || 0),
    useSubgraph: !!parseInt(process.env.REACT_APP_USE_SUBGRAPH || 0),
    subgraphURI: process.env.REACT_APP_SUBGRAPH_URI,
    desiredNetwork: NETWORKS[process.env.REACT_APP_CHAIN],
    policyAddress: process.env.REACT_APP_POLICY_ADDRESS,
  };
} else {
  // development
  global.env = {
    debug: true,
    onlyTrustees: !!parseInt(process.env.REACT_APP_ONLY_TRUSTEES || 0),
    useSubgraph: !!parseInt(process.env.REACT_APP_USE_SUBGRAPH || 0),
    subgraphURI:
      process.env.REACT_APP_SUBGRAPH_URI ||
      'http://localhost:8000/subgraphs/name/paged1/Policy',
    desiredNetwork: NETWORKS[process.env.REACT_APP_CHAIN],
    policyAddress: process.env.REACT_APP_POLICY_ADDRESS,
  };
}
