import React from 'react';

import { List } from 'antd';
import { useGovernance } from '../providers/GovernanceProvider';
import Vote from './Vote';

/**
 * Vote List
 *
 * a verbose list of proposals,
 * showing all parameters of each:
 * - trustee
 * - vote: the sorted array of trustees that this trustee voted for
 */

export default function VoteList() {
  const gov = useGovernance();

  return (
    <List
      bordered
      //itemLayout="vertical"
      size="large"
      dataSource={gov.votes}
      renderItem={(vote) => (
        <Vote
          {...vote}
          proposals={gov.proposals}
          key={'vote-' + vote.trustee.toString()}
        />
      )}
    />
  );
}
