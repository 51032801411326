import { gql } from '@apollo/client';

type SubgraphContractAddresses = {
  eco: string;
};

type SubgraphToken = {
  totalSupply: string;
};

type SubgraphInflationMultiplier = {
  value: string;
};

export type EcoTokenQueryResult = {
  contractAddresses: SubgraphContractAddresses | null;
  token: SubgraphToken | null;
  inflationMultipliers: SubgraphInflationMultiplier[];
};

export const ECO_TOKEN = () => gql`
  query ecoToken {
    contractAddresses(id: "0") {
      eco
    }
    token(id: "eco") {
      totalSupply
    }
    inflationMultipliers(orderBy: blockNumber, orderDirection: desc, first: 1) {
      value
    }
  }
`;
