import './env.js';

import '@babel/polyfill';

import ReactDOM from 'react-dom';

import { HashRouter } from 'react-router-dom';
import App from './App';

import './index.css';
import 'antd/dist/antd.less';

import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import ContractsProvider from './providers/ContractAddressProvider';

import TrusteeProvider from './providers/TrusteeProvider';
import Erc20Provider from './providers/Erc20Provider';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const apolloClient = new ApolloClient({
  uri: global.env.subgraphURI,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ThemeSwitcherProvider themeMap={themes} defaultTheme={'light'}>
    <ApolloProvider client={apolloClient}>
      <ContractsProvider>
        <Erc20Provider>
          <TrusteeProvider>
            <HashRouter style={{ height: '100%' }}>
              <App />
            </HashRouter>
          </TrusteeProvider>
        </Erc20Provider>
      </ContractsProvider>
    </ApolloProvider>
  </ThemeSwitcherProvider>,
  document.getElementById('root')
);
