import { Row, Col, Typography } from 'antd';
import ProposalTable from './ProposalTable';

function GenerationResults({
  winningProposal,
  losingProposals,
  scores,
  maxScore,
  signer,
}) {
  return (
    <div>
      <Row justify="space-around" align="top" gutter={[24, 32]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={12}>
          <Typography.Title level={3} style={{ textAlign: 'left' }}>
            Winning Proposal
          </Typography.Title>
          <ProposalTable
            list={winningProposal ? [winningProposal] : []}
            scores={scores}
            maxScore={maxScore}
            signer={signer}
          />
        </Col>
      </Row>

      <br />
      <Row justify="space-around" align="top" gutter={[24, 32]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={12}>
          <Typography.Title level={3} style={{ textAlign: 'left' }}>
            Proposals not selected
          </Typography.Title>
          <ProposalTable
            list={losingProposals}
            scores={scores}
            maxScore={maxScore}
            signer={signer}
          />
        </Col>
      </Row>
    </div>
  );
}

export default GenerationResults;
