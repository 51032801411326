import { ReactChild, useContext } from 'react';

import InfuraTrusteeProvider, {
  InfuraTrusteeContext,
} from './InfuraTrusteeProvider';
import SubgraphTrusteeProvider, {
  SubgraphTrusteeContext,
} from './SubgraphTrusteeProvider';

let globalAny: any = global;

type TrusteeProviderProps = {
  children: ReactChild;
};

export default function TrusteeProvider({ children }: TrusteeProviderProps) {
  if (globalAny.env.useSubgraph) {
    return <SubgraphTrusteeProvider>{children}</SubgraphTrusteeProvider>;
  } else {
    return <InfuraTrusteeProvider>{children}</InfuraTrusteeProvider>;
  }
}

export const useTrustees = () =>
  useContext(
    globalAny.env.useSubgraph ? SubgraphTrusteeContext : InfuraTrusteeContext
  );
