import { Address } from '../types';

export { default as changeNetwork } from './changeNetwork';
export { default as download } from './download';
export { default as toastTxError } from './toastTxError';
export { default as proposalToDisplay } from './proposalToDisplay';
export { default as proposalToOnChain } from './proposalToOnChain';
export { default as limitDecimals } from './limitDecimals';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const isNullAddress = (address: string | Address | null) => {
  if (!address) {
    return true;
  } else if (typeof address === 'string') {
    return address === NULL_ADDRESS;
  } else {
    return address.toString() === NULL_ADDRESS;
  }
};

export const shortAddress = (address: Address | string) => {
  let addressString = address.toString();
  return (
    addressString.substring(0, 6) +
    ' ... ' +
    addressString.substring(addressString.length - 4)
  );
};

// returns UNIX timestamp in seconds
export function currTime() {
  return Math.round(Date.now() / 1000);
}

export function formatNumber(
  val: string,
  setter: (str: string) => void,
  onlyInteger = false
) {
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(val) || val === '' || val === '-') {
    if (!onlyInteger) {
      setter(val.startsWith('.') ? '0' + val : val);
    } else if (!val.startsWith('.')) {
      setter(
        val && !val.startsWith('-')
          ? Math.round(parseFloat(val)).toString()
          : val
      );
    }
  }
}

// convert a percentage of erc20 to number of erc20
export function percentToNumberOf(percent: number, supply: number) {
  return Math.round((percent / 100) * supply);
}
