import { ReactNode, createContext, useState, useEffect } from 'react';

import { Address, ContractAddressesInterface } from '../../types';
import { useApolloClient } from '@apollo/client';
import { ContractAddressQueryResult, CONTRACT_ADDRESSES } from '../../queries';

/**
 * SubgraphContractAddressProvider
 *
 * Fetches most recent contract addresses pointed to by the registry through the subgraph
 *
 */

const DEFAULT_CONTRACT_ADDRESSES: ContractAddressesInterface = {
  eco: null,
  timedPolicies: null,
  currencyGovernance: null,
  trustedNodes: null,
  currencyTimer: null,
};

export const SubgraphContractAddressContext =
  createContext<ContractAddressesInterface>(DEFAULT_CONTRACT_ADDRESSES);

type ContractAddressProviderProps = {
  children: ReactNode;
};

const SubgraphContractAddressProvider = ({
  children,
}: ContractAddressProviderProps) => {
  const apolloClient = useApolloClient();

  const [contractAddresses, setContractAddresses] =
    useState<ContractAddressesInterface>(DEFAULT_CONTRACT_ADDRESSES);

  // if contractName is specified, will return that contract's address
  const refreshContractAddresses = async () => {
    try {
      const result = await apolloClient.query<ContractAddressQueryResult>({
        query: CONTRACT_ADDRESSES(),
        fetchPolicy: 'no-cache',
      });

      const contractAddresses = result.data.contractAddresses;

      if (contractAddresses) {
        setContractAddresses({
          currencyTimer: new Address(contractAddresses.currencyTimer),
          currencyGovernance: new Address(contractAddresses.currencyGovernance),
          timedPolicies: new Address(contractAddresses.timedPolicies),
          eco: new Address(contractAddresses.eco),
          trustedNodes: new Address(contractAddresses.trustedNodes),
        });

        return contractAddresses;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    refreshContractAddresses();
    const pollTimer = setInterval(() => refreshContractAddresses(), 5_000);

    return () => clearInterval(pollTimer);
  }, []);

  return (
    <SubgraphContractAddressContext.Provider value={contractAddresses}>
      {contractAddresses ? children : null}
    </SubgraphContractAddressContext.Provider>
  );
};

export default SubgraphContractAddressProvider;
