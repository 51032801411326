import React from 'react';

import { List, Typography } from 'antd';

import { useGovernance } from '../providers/GovernanceProvider';

/**
 * Commit List
 *
 * list of trustees who have committed
 *
 */

export default function CommitList() {
  const gov = useGovernance();

  return (
    <List
      bordered
      //itemLayout="vertical"
      dataSource={gov.commits}
      renderItem={(commit) => (
        <Commit
          key={'commit-' + commit.trustee.toString()}
          trustee={commit.trustee}
        />
      )}
    />
  );
}

// Commit component
function Commit({ trustee }) {
  return (
    <List.Item style={{ maxHeight: '100%' }}>
      <List.Item.Meta
        style={{ textAlign: 'left' }}
        title={<Typography.Text>Trustee: {trustee.toString()}</Typography.Text>}
        description={null}
      />
    </List.Item>
  );
}
