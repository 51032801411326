import React, { useState } from 'react';

import { List, Typography, Modal } from 'antd';
import ProposalTable from './ProposalTable';

import { isNullAddress } from '../helpers';

/**
 * Vote component
 *
 * not to be confused with the Commit view, which uses the /vote route
 *
 * displays a trustee and the proposals they voted for, in order, highest priority first
 *
 * props available:
 * - trustee: the wallet address that this vote belongs to
 * - vote: the array of trustee addresses
 */

export default function Vote({ trustee, rankedProposals, proposals }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <List.Item
      extra={null}
      style={{ maxHeight: '100%', cursor: 'pointer' }}
      onClickCapture={() => setModalOpen(true)}
    >
      <List.Item.Meta
        style={{ textAlign: 'left' }}
        title={<Typography.Text>Trustee: {trustee.toString()}</Typography.Text>}
        description={rankedProposals.map((address, i) => {
          return (
            <div key={'vote-' + i}>
              <Typography.Paragraph type="secondary" style={{ margin: 4 }}>
                {i + 1}:{' '}
                {isNullAddress(address)
                  ? 'Default Proposal'
                  : address.toString()}
              </Typography.Paragraph>
            </div>
          );
        })}
      />
      <Modal
        title={`${trustee.toString()}'s Vote`}
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}
        closable={false}
        footer={null}
        width={1000}
      >
        <ProposalTable
          ranked
          list={rankedProposals
            .map((rankedProposal) =>
              proposals.find((proposal) => proposal.trustee.eq(rankedProposal))
            )
            .filter((prop) => !!prop)}
        />
      </Modal>
    </List.Item>
  );
}
