import { Skeleton, Typography } from 'antd';
import React from 'react';
import Blockies from 'react-blockies';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { shortAddress } from '../helpers';
import { useLookupAddress } from '../hooks';

// changed value={address} to address={address}

/*
    (scaffold-eth component)
  ~ What it does? ~

  Displays an address with a blockie image and option to copy address

  ~ How can I use? ~

  <Address
    address={address}
    short
    ensProvider={mainnetProvider}
    blockExplorer={blockExplorer}
    minimized
    fontSize={fontSize}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
  - Provide fontSize={fontSize} to change the size of address text
*/

const blockExplorerLink = (address, blockExplorer) =>
  `${blockExplorer || 'https://etherscan.io'}${'/address/'}${address}`;

export default function Address({
  address,
  short,
  ensProvider,
  blockExplorer,
  minimized,
  fontSize,
}) {
  const ens = useLookupAddress(ensProvider, address);

  const { currentTheme } = useThemeSwitcher();

  if (!address) {
    return (
      <span>
        <Skeleton avatar paragraph={{ rows: 1 }} />
      </span>
    );
  }

  let displayAddress = address;

  if (ens && ens.indexOf('0x') < 0) {
    displayAddress = ens;
  } else if (short) {
    displayAddress = shortAddress(displayAddress);
  }

  const etherscanLink = blockExplorerLink(address, blockExplorer);

  return minimized ? (
    <span style={{ verticalAlign: 'middle' }}>
      <a
        style={{
          color: currentTheme === 'light' ? '#222222' : '#ddd',
        }}
        target="_blank"
        href={etherscanLink}
        rel="noopener noreferrer"
      >
        <Blockies
          style={{ height: '32px' }}
          seed={address.toLowerCase()}
          size={6}
          scale={2}
        />
      </a>
    </span>
  ) : (
    <span
      style={{
        verticalAlign: 'middle',
        paddingLeft: 5,
        fontSize: fontSize || 16,
      }}
    >
      <Typography.Text copyable={{ text: address }}>
        <a
          style={{
            color: currentTheme === 'light' ? '#222222' : '#ddd',
          }}
          target="_blank"
          href={etherscanLink}
          rel="noopener noreferrer"
        >
          {displayAddress}
        </a>
      </Typography.Text>
    </span>
  );
}
