import { BigNumber, utils } from 'ethers';

// 1e18
const defaultInflation = BigNumber.from('1000000000000000000');

const secondsPerDay = 86400;

// convert a whole proposal from object with object params to object with display friendly attributes
export default function proposalToDisplay(proposal, totalSupply) {
  let convertedProposal = { ...proposal };

  convertedProposal.randomInflationReward = randomInflationRewardParamToDisplay(
    // get total percent by multiplying number of winners by individual prize
    proposal.numberOfRecipients.mul(proposal.randomInflationReward),
    totalSupply
  );

  convertedProposal.numberOfRecipients = numberOfRecipientsParamToDisplay(
    proposal.numberOfRecipients
  );

  convertedProposal.lockupDuration = lockupDurationParamToDisplay(
    proposal.lockupDuration
  );

  convertedProposal.lockupInterest = proposal.lockupDuration.gt(0)
    ? 
      ((lockupInterestParamToDisplay(proposal.lockupInterest) / convertedProposal.lockupDuration) * 365)
    : 0;

  convertedProposal.inflationMultiplier = multiplierParamToDisplay(
    proposal.inflationMultiplier
  );

  return convertedProposal;
}

// convert from bignumber to percentage integer
// (multiply by 100 first to avoid BigNumber underflow and end up with the percentage in integer format)
export function randomInflationRewardParamToDisplay(onChainValue, totalSupply) {
  return (
    utils.formatUnits(onChainValue.mul(100), 18) /
    utils.formatUnits(totalSupply, 18)
  );
}
export function numberOfRecipientsParamToDisplay(onChainValue) {
  return onChainValue.toString();
}

// convert from seconds to days
export function lockupDurationParamToDisplay(onChainValue) {
  return onChainValue.toNumber() / secondsPerDay;
}

// convert back to integer percentage
export function lockupInterestParamToDisplay(onChainValue) {
  return utils.formatUnits(onChainValue, 7);
}

// convert linear inflation BigNumber onChainValue to display value
export function multiplierParamToDisplay(onChainValue) {
  var displayValue = 0;
  if (!onChainValue.eq(defaultInflation)) {
    displayValue = parseFloat(
      (100 / parseFloat(utils.formatUnits(onChainValue)) - 100).toFixed(4)
    );
  }
  return displayValue;
}
